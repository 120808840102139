import { useEffect } from "react";

import { Modal } from "@web-solutions/core/ui-elements";
import Analytics from "@web-solutions/module-analytics";
import { EVENT_ACTION } from "@web-solutions/core/constants/general";

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { t } from "../../../../../localization";

import { useManageContext } from "../../../../hooks/use-manage-context";

import { ReactComponent as CardIcon } from './icons/card.svg';
import { ReactComponent as RefreshIcon } from './icons/refresh.svg';
import { ReactComponent as PauseIcon } from './icons/pause.svg';
import { ReactComponent as GestureIcon } from './icons/gesture.svg';

import classes from './style.module.scss';

const tKey = 'manage_v2.main.info_modal';

interface Props {
  visible: boolean;
  onClose: () => void;
  onChange: () => void;
  onPause: () => void;
  onCancel: () => void;
  onPaymentMethodChange: () => void;
}

export const InfoModal: React.FC<Props> = ({ visible, onClose, onChange, onPause, onCancel, onPaymentMethodChange }) => {
  const { mngSubPausePage } = useRemoteConfig()

  const { pauseSubscriptionConfig } = useManageContext();

  useEffect(() => {
    if (visible) {
      Analytics.trackEvent('manage_main_info_modal', EVENT_ACTION.OPEN);
    }
  }, [visible])

  const handleClick = (id: string) => () => {
    Analytics.trackEvent('manage_main_info_modal', EVENT_ACTION.CLICK, { option: id });
    switch (id) {
      case 'method':
        onPaymentMethodChange();
        break;
      case 'change':
        onChange();
        break;
      case 'pause':
        onPause();
        break;
      case 'cancel':
        onCancel();
        break;
      default:
        break;
    }
  }

  const handleCloseClick = () => {
    Analytics.trackEvent('manage_main_info_modal', EVENT_ACTION.CLOSE);
    onClose();
  }

  return <Modal
    isOpen={visible}
    className={classes.modal}
    overlayClassName={classes.overlay}
  >
    <div className={classes.wrapper}>
      <div className={classes.close} onClick={handleCloseClick}>
        <GestureIcon />
      </div>
      <div className={classes.option} onClick={handleClick('method')} >
        <CardIcon />
        {t(`${tKey}.method`)}
      </div>
      <div className={classes.option} onClick={handleClick('change')} >
        <RefreshIcon />
        {t(`${tKey}.change`)}
      </div>
      {pauseSubscriptionConfig?.enabled && !!mngSubPausePage?.enabled && (
        <div className={classes.option} onClick={handleClick('pause')} >
          <PauseIcon />
          {t(`${tKey}.pause`)}
        </div>
      )}
      <div className={classes.option} onClick={handleClick('cancel')} >
        <GestureIcon />
        {t(`${tKey}.cancel`)}
      </div>
    </div>
  </Modal>
}