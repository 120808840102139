import { type FC, Fragment, ComponentProps } from 'react';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';
import { t } from '@web-solutions/module-localization';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { useRemoteConfig } from '@web-solutions/base-app/hooks/use-remote-config';

import { TermsCheckBox } from '@web-solutions/core/containers/terms-checkbox';

import classes from './style.module.scss';

const tKey = 'core.agreement';

const POLICY_TEXT_CONFIG = ['policy', 'privacy_policy', 'terms', 'billing_terms', 'and', 'money_back'] as const

const POLICY_TEXT_TYPE_LOOKUP: PolicyTextLookup<'link' | 'text'> = {
  privacy_policy: 'link',
  terms: 'link',
  billing_terms: 'link',
  money_back: 'link',
  policy: 'text',
  and: 'text'
}

type PolicyTextLookup<T extends string = string> = Record<typeof POLICY_TEXT_CONFIG[number], T>

interface Props {
  status?: boolean | null;
}

export const PolicyText: FC<ComponentProps<'div'> & Props> = ({ className, children, status }) => {
  const {
    policyLinks,
  } = useRemoteConfig();

  const policyLinksLookup: Partial<PolicyTextLookup> = {
    privacy_policy: policyLinks.privacy,
    terms: policyLinks.termsOfUse,
    billing_terms: policyLinks.billingTerms,
    money_back: policyLinks.moneyBack,
  };

  const handleLinkClick = (link?: string) => () => {
    Analytics.trackEvent('sub_main_payment_policy', EVENT_ACTION.CLICK, { link });
  };

  return (
    <div className={classNames(classes.confirmation, className, { [classes.with_checkbox]: children })}>
      {children &&
        <span className={classes.checkbox}>
          <TermsCheckBox status={status} />
        </span>
      }
      <span className={classes.text}>
        {POLICY_TEXT_CONFIG.map((key) =>
          <Fragment key={key}>
            {
              POLICY_TEXT_TYPE_LOOKUP[key] === 'link'
                ?
                <a
                  href={policyLinksLookup[key]}
                  className={classes.link}
                  onClick={handleLinkClick(policyLinksLookup[key])}
                >
                  {t(`${tKey}.${key}`)}
                </a>
                :
                t(`${tKey}.${key}`)
            }&nbsp;
          </Fragment>
        )}
      </span>
      <span>{children}</span>
    </div >
  );
};
