

import type { RemoteConfig } from '@web-solutions/core/constants/remote-config';

import { baseTKey } from '../constants'

import type { FormattedPeriod } from '../types';

const tKey = `${baseTKey}.period`

export const getFormattedPeriodsConfig = (periods: Required<NonNullable<RemoteConfig['mngSubPausePage']>>['pausePeriodsInWeeks']): FormattedPeriod[] => {
  const formattedPeriods = periods.map((weeksAmount) => {
    if (weeksAmount % 4 === 0) {
      const monthAmount = weeksAmount / 4

      return {
        titleKey: `${tKey}.month`,
        amount: monthAmount,
        value: monthAmount * 30
      }
    } else {
      return {
        titleKey: `${tKey}.week`,
        amount: weeksAmount,
        value: weeksAmount * 7
      }
    }
  })

  return [
    ...formattedPeriods,
    {
      titleKey: `${tKey}.no`,
      amount: 0,
      value: 0
    },
  ]
}