import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import Analytics from '@web-solutions/module-analytics';
import { Button } from '@web-solutions/core/ui-elements';

import Checkbox from '../../../components/checkbox-dot';

import { REASONS_IDS_V2 } from '../../../constants/general';

import { t } from '../../../../localization';
import { useManageNavigation } from '../../../hooks/use-manage-navigate';
import { Header } from '../../../components/header';

import classes from './style.module.scss';

const tKey = 'manage.reason';
const category = 'manage_reason';

export const SelectReasonScreenV2: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navigateNextScreen, navigatePrevSceen } = useManageNavigation();
  const [selectedReason, setSelectedReason] = useState<string | null>(null);

  useEffect(() => {
    Analytics.trackEvent(category, 'open');
  }, []);

  const handleContinueClick = () => {
    Analytics.trackEvent(category, 'submit', { reason: selectedReason });
    const params = queryString.parse(window.location.search);
    params.reason = selectedReason
    navigate({
      pathname: location.pathname,
      search: queryString.stringify(params),
    },
      { replace: true });

    navigateNextScreen();
  };

  const handleReasonClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedReason(e.target.id);
  }

  const handleBackClick = () => {
    Analytics.trackEvent(category, 'back');
    navigatePrevSceen();
  }

  return (
    <div className={classes.wrap}>
      <Header
        titleClassName={classes.title}
        className={classes.header}
        title={t(`${tKey}.title`)}
        withBackButton
        onBackClick={handleBackClick}
      />
      <div className={classes.reasons}>
        {Object.values(REASONS_IDS_V2).map((id) => (
          <Checkbox
            key={id}
            id={id}
            isChecked={id === selectedReason}
            label={t(`${tKey}.reasons.${id}`)}
            onChange={handleReasonClick}
            classNameCheckbox={classes.checkbox}
          />
        ))}
      </div>
      <div className={classes.bottom}>
        <Button
          disabled={!selectedReason}
          onClick={handleContinueClick}
          titleClassName={classes.button}
          type="button"
          className={classes.bottom_button}
          title={t(`${tKey}.button_title`)}
        />
      </div>
    </div>
  );
};
