import React, { useRef, useState, useCallback, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Provider } from '@web-solutions/react-billing';
import Analytics from '@web-solutions/module-analytics';

import { t } from '@web-solutions/module-localization';

import {
  selectLoading,
  selectPaymentSystem,
  type ProductDetails,
} from '@web-solutions/core/store/billing/selectors';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import BasePaymentModal from '@web-solutions/core/payment/base';
import ThreeDSecure from '@web-solutions/core/payment/components/threeDSecure';
import { Preloader } from '@web-solutions/core/ui-elements';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import { useShowMercadoPago, useShowUPI } from '@web-solutions/core/hooks/use-show-payment-method';

import type { SubscribeOnOneClickParams } from '@web-solutions/core/store/billing/types';
import type { PaymentError } from '@web-solutions/core/interfaces/errors';
import type { OrderDetails, SolidMetadata, Subscription } from '@web-solutions/core/interfaces/billing';
import type { PaymentModalTitle } from '@web-solutions/core/interfaces/payment-modal';

import { initOrders } from '@web-solutions/core/store/billing/actions';

import ErrorPopup from './components/error-popup';
import { SubscribeOnOneClick, type SubscribeOnOneClickRef } from './components/subscribe-on-one-click'

import {
  useGooglePayProcessor,
  useApplePayProcessor,
  useErrorPopupProcessor,
  useCheckRedirectResults,
  usePayPalProcessor,
  usePaymentProcessorBase,
  useGetCardErrorHandler
} from './hooks';

export interface PaymentProcessorRef {
  showPaymentPopup: () => void;
  closePaymentPopup: () => void;
  processFormData: (data: any) => void;
  subscribeOnOneClick: (data: SubscribeOnOneClickParams) => void;
}

export interface PaymentProcessorProps {
  activeProduct: ProductDetails,
  fullMode?: boolean,
  orderDetails: OrderDetails | null,
  isOnlyBaseModal?: boolean;
  paymentModalTitle?: PaymentModalTitle,
  isOnlyCard?: boolean,
  isOnlyPayPal?: boolean,
  errorPopupWithPayPal?: boolean,
  hidePayPal?: boolean,
  onSuccess: (purchase: Subscription) => void,
  onError?: (error: any) => void,
  onClose?: () => void,
  onSubmit?: (formData: any) => void;
  onSubscribeOnOneClickError?: (error: PaymentError) => void;
  isPurchaseProgress?: boolean,
  upsaleTrigger?: string,
  orderMetadata?: any;
  orderSolidMetadata?: SolidMetadata;
  giftContent?: React.ReactNode;
}

const tKeyErrorPopup = 'core.subscription.error_popup.prepaid';

export const PaymentProcessor = React.forwardRef<PaymentProcessorRef, PaymentProcessorProps>(({
  activeProduct,
  fullMode,
  paymentModalTitle,
  upsaleTrigger,
  orderDetails,
  orderMetadata,
  orderSolidMetadata,
  isOnlyBaseModal,
  isOnlyCard,
  isOnlyPayPal,
  isPurchaseProgress,
  hidePayPal,
  errorPopupWithPayPal,
  giftContent,
  onSuccess,
  onClose,
  onError,
  onSubmit,
  onSubscribeOnOneClickError,
}, ref) => {
  const socRef = useRef<SubscribeOnOneClickRef>(null)

  const dispatch = useDispatch();

  const [isOpenPaymentPopup, setIsOpenPaymentPopup] = useState(false);

  const isPending = useSelector((state: any) => state.billing.pending || state.billing.orderPending);
  const isLoadingBilling = useSelector(selectLoading);
  const isLoadingApp = useSelector((state: any) => !state.app.loaded);

  const {
    modeCardForm,
    errorPopup,
    prepaidCards,
  } = useRemoteConfig();

  const paymentSystem = useSelector(selectPaymentSystem);

  const showMercadoPago = useShowMercadoPago() && !activeProduct.isOneTimePurchase;
  const showUPI = useShowUPI() && !activeProduct.isOneTimePurchase;

  const {
    errorCode,
    isOpenErrorPopup,
    setIsOpenErrorPopup,
    setErrorCode,
    handleErrorPopupSubmit,
    handleErrorPopupCancel
  } = useErrorPopupProcessor({
    onCancel: () => setIsOpenPaymentPopup(true),
    onSubmit: () => setIsOpenPaymentPopup(true),
    onClose,
  })

  const {
    onCardError
  } = useGetCardErrorHandler({
    activeProduct,
    setErrorCode,
    setIsOpenErrorPopup,
    onErrorTrigger: () => setIsOpenPaymentPopup(false),
    onError,
  })

  const handleCheckPrepaid = (type: 'card' | 'alternative', options?: { method?: string }) => {
    if (type === 'card' && prepaidCards.allowCardMethod) return;
    if (type === 'alternative' && prepaidCards.allowAlternativeMethods) return;
    Analytics.trackEvent('prepaid_card', EVENT_ACTION.ERROR, { ...options })
    setErrorCode(type);
    setIsOpenErrorPopup(true);
    dispatch(initOrders());
  };

  const {
    formOptions,
    tokenThreeDSecure,
    processFormData,
    handleSubmit,
    handlePaymentSuccess,
    handleCardFormSubmit,
    handleCardPaymentSuccess,
    handleCardPaymentError,
    handle3DTokenExchanged,
    handle3DTokenError,
    handleUserInfoChange,
  } = usePaymentProcessorBase({
    activeProduct,
    paymentSystem,
    orderDetails,
    onCheckPrepaid: handleCheckPrepaid,
    onCardSuccess: () => setIsOpenPaymentPopup(false),
    onCardError,
    onSuccess,
    onError,
    onSubmit,
  })

  useImperativeHandle(ref, () => ({
    showPaymentPopup() {
      setIsOpenPaymentPopup(true);
    },
    closePaymentPopup() {
      setIsOpenPaymentPopup(false);
    },
    processFormData(data: any) {
      processFormData(data);
    },
    subscribeOnOneClick(data: SubscribeOnOneClickParams) {
      socRef.current?.subscribeOnOneClick(data)
    }
  }), [processFormData]);

  useCheckRedirectResults({ handlePaymentSuccess })

  const {
    showApplePay,
    handleApplePayClick,
    handleApplePaySubmit
  } = useApplePayProcessor({
    activeProduct,
    paymentSystem,
    trigger: upsaleTrigger,
    orderMetadata,
    orderSolidMetadata,
    handleCardPaymentError,
    handlePaymentSuccess,
    handleSubmit
  });

  const {
    showGooglePay,
    handleGooglePayClick,
    handleGooglePaySubmit
  } = useGooglePayProcessor({ handleSubmit });

  const {
    showPayPal,
    handlePayPalSubmit
  } = usePayPalProcessor({
    activeProduct,
    onSubmit: processFormData
  });

  const handleSubscribeOnOneClickError = useCallback((error: PaymentError) => {
    if (onSubscribeOnOneClickError) {
      onSubscribeOnOneClickError(error);
    } else {
      setErrorCode(error?.code)
      setIsOpenErrorPopup(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubscribeOnOneClickError]);

  const handleClosePaymentPopup = useCallback(() => {
    Analytics.trackEvent('modal_payment', EVENT_ACTION.CLOSE);

    setIsOpenPaymentPopup(false);

    onClose && onClose();
  }, [onClose]);

  const isPrepaid = errorCode === 'card' || errorCode === 'alternative';

  return (
    <>
      {(isLoadingApp || isLoadingBilling) ?
        <Preloader className='PaymentModalPreloader' /> :
        <Provider>
          <BasePaymentModal
            activeProduct={activeProduct}
            giftContent={giftContent}
            title={paymentModalTitle}
            upsaleTrigger={upsaleTrigger}
            options={formOptions}
            fullMode={fullMode}
            showPopup={isOpenPaymentPopup}
            showMercadoPago={showMercadoPago}
            showUPI={showUPI}
            showCard={isOnlyPayPal !== undefined ? !isOnlyPayPal : modeCardForm !== 'disabled'}
            showApplePay={!!showApplePay && !isOnlyCard && !isOnlyPayPal}
            showGooglePay={!!showGooglePay && !isOnlyCard && !isOnlyPayPal}
            showPayPal={showPayPal && !isOnlyCard && !hidePayPal}
            isOnlyCard={isOnlyCard}
            isOnlyBaseModal={isOnlyBaseModal}
            isPurchaseProgress={isPurchaseProgress}
            onClose={handleClosePaymentPopup}
            onSuccess={handleCardPaymentSuccess}
            onError={handleCardPaymentError}
            onSubmit={handleCardFormSubmit}
            onUserInfoChange={handleUserInfoChange}
            onApplePayClick={handleApplePayClick}
            onApplePaySubmit={handleApplePaySubmit}
            onGooglePayClick={handleGooglePayClick}
            onGooglePaySubmit={handleGooglePaySubmit}
            onPayPalSubmit={handlePayPalSubmit}
            onPayPalSuccess={handleCardPaymentSuccess}
            onPayPalError={handleCardPaymentError}
            onCheckPrepaid={handleCheckPrepaid}
          />
        </Provider>
      }

      {tokenThreeDSecure && (
        <ThreeDSecure
          actionTokenId={tokenThreeDSecure}
          onToken={handle3DTokenExchanged}
          onError={handle3DTokenError}
        />
      )}

      <SubscribeOnOneClick
        ref={socRef}
        onSuccess={onSuccess}
        onError={handleSubscribeOnOneClickError}
      />

      {errorPopup?.enabled && (
        <ErrorPopup
          product={activeProduct}
          statusCode={errorCode}
          buttonTitle={!!isPrepaid ? t(`${tKeyErrorPopup}.button`) : errorPopup.buttonTitle}
          visible={isOpenErrorPopup}
          withPayPal={errorPopupWithPayPal}
          onClose={handleErrorPopupCancel}
          onPayPalSubmit={handlePayPalSubmit}
          onPayPalSuccess={handleCardPaymentSuccess}
          onPayPalError={handleCardPaymentError}
          onSubmit={handleErrorPopupSubmit}
        />
      )}

      {!!isPending && <Preloader className='PaymentModalPreloader' />}
    </>
  );
});
