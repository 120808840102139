import { useSelector } from 'react-redux';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { Survey } from '@web-solutions/core/containers/survey'

import { Theme } from '@web-solutions/core/constants/theme';

import { selectSubscription } from '../../slice';
import { SubscriptionStatus } from '../../types';

import { useManageNavigation } from '../../hooks/use-manage-navigate';

export const ManageSurvey: React.FC = () => {
  const { manageSurvey } = useRemoteConfig();
  const { navigateNextScreen } = useManageNavigation();
  const subscription = useSelector(selectSubscription);
  const status = subscription?.status as SubscriptionStatus.ACTIVE | SubscriptionStatus.TRIAL
  const link = manageSurvey?.link
  const enableClose = manageSurvey?.enableClose

  const handleSurveyFinish = () => {
    navigateNextScreen();
  }

  return (
    <Survey
      theme={Theme.dark}
      link={link[status]}
      enableClose={enableClose}
      analyticsCategory="manage_survey"
      onSurveyFinish={handleSurveyFinish}
    />
  );
};
