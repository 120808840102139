import { createAsyncThunk } from '@reduxjs/toolkit';
import _pick from 'lodash/pick';
import _random from 'lodash/random';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import { ThunkAction } from 'redux-thunk';
import i18next from 'i18next';
import dayjs from 'dayjs';

import Billing from '@web-solutions/module-billing';
import Analytics from '@web-solutions/module-analytics';
import Attribution from '@web-solutions/module-attribution';

import { updateFirebaseUserEmail } from '@web-solutions/account-management/api/auth';
import { birthDate2Age } from '@web-solutions/core/utils/profile/birth-date-to-age';

import { setApiAuth } from 'core/utils/network';
import { stringifyUrlParams } from 'core/utils/url-sync';
import { getApproximateLocation } from 'core/api/nominatim';

import { auth as authRequest } from 'src/api/auth';
import { update as updateProfile, G_IDS, R_IDS, saveFutureGoals, updateProfileParams } from 'src/api/profile';
import { RELATIONSHIP } from 'src/constants/profile';
import { maxMatchingPercent, minMatchingPercent } from 'src/screens/quiz/advisors-loader/constants';

import { bestAdvisorsData } from 'src/components/advisor-cards-swiper/constants';
import { AdvisorCardData } from 'src/components/advisor-cards-swiper/types';

import { isAsb } from '../app/utils';
import { setPending } from '../app/actions';
import type { AppThunk, RootState } from '../types'

import { profileParamsMapper } from './utils';

import { profileSlice } from '.';


export const {
  setAge,
  setApproximateLocation,
  setAstologerTimeBalance,
  setAstrologerTimeBalance,
  setAuthorized,
  setBirthDate,
  setBirthPlace,
  setBirthTime,
  setColor,
  setCriticalDirection,
  setDesirableGoals,
  setElement,
  setEmail,
  setFutureGoals,
  setGender,
  setGoals,
  setIsUnsubscriber,
  setLang,
  setName,
  setRelStatus,
  setRelyOn,
  setPersonalInsight,
  setShippingAddress,
  setShippingMethod,
  generateSoulmateInfo,
  setAdvisorsData,
  setSelectedAdvisorId,
  setChatUnlimited,
  setTopics,
  setTypesOfReading,
  setPauseSubUsed,
  setAppliedPromocodes
} = profileSlice.actions;

export const processEmail = (email: string, { skipFirebase }: { skipFirebase?: boolean } = {}): ThunkAction<void, any, unknown, any> =>
  (dispatch, getState) => {
    if (email !== getState().profile.email) {
      Analytics.setUserProperty('email', email);
      Analytics.trackEvent('user', 'info', { email, });

      dispatch(setEmail(email));

      stringifyUrlParams({ email })

      if (!skipFirebase) {
        updateFirebaseUserEmail({ email });
      }
    }
  }


export const saveFutureGoalsData = createAsyncThunk(
  `${profileSlice.name}/saveFutureGoalsData`,
  async (goals: Record<string, any>[], { dispatch }) => {
    dispatch(setFutureGoals(goals));
    const data = {
      goals: goals.map((goal) => goal.id)
    }
    saveFutureGoals(data)
      .catch((e) => {
        console.log('[SAVE GOALS ERROR]: ', e)
      })
  })

export const auth = createAsyncThunk(
  `${profileSlice.name}/auth`,
  async (_, { dispatch }) => {
    return authRequest()
      .then((res: any) => {
        const h = `Bearer ${res?.profile?.access_token}`;
        setApiAuth(h);
        Billing.setApiAuth(h);

        if (window.location.pathname.split('/')[1] === 'manage' || sessionStorage.getItem('completed') === 'true') {
          if (res?.profile?.name) {
            dispatch(setName(res?.profile?.name));
          }

          if (res?.profile?.birthdate && res?.profile?.birthdate !== '2000-01-01') {
            dispatch(setBirthDate(res?.profile?.birthdate));
          }
          //TODO: check data from backend
          dispatch(setGender(G_IDS[res?.profile?.gender] || null));
          dispatch(setRelStatus(R_IDS[res?.profile?.marital_status] || null));
          dispatch(setChatUnlimited(res?.profile?.data?.is_chat_unlimited));
          dispatch(setLang(i18next.language));
          dispatch(setPauseSubUsed(res?.profile?.data?.used_pause))
        }

        dispatch(setEmail(res?.profile?.email));
        dispatch(setAuthorized());

        if (!isAsb()) {
          getApproximateLocation()
            .then(r => {
              dispatch(setApproximateLocation(r));
            })
            .catch((err) => {
              console.warn(err);
            })
        }
      });
  }
)

export const authAndSetUserData = createAsyncThunk(
  `${profileSlice.name}/authAndSetUserData`,
  async (isSetPending: boolean | undefined, { dispatch, getState }) => {
    const { profile: p, remoteConfig } = getState() as RootState;

    if (isSetPending) {
      dispatch(setPending(true));
    }

    const { params: profileParams } = profileParamsMapper(
      {
        traffic_source: remoteConfig.trafficSource,
        with_contact_us_btn_in_check_email: remoteConfig.withContactUsBtnInCheckEmail,
        withAccountManage: true,
        show_subscription_manage_text: remoteConfig.showSubscriptionManageText
      }
    )

    try {
      await updateProfileParams(profileParams)
    } catch (error) {
      console.log('[ERROR UPDATE PROFILE PARAMS]', error);
    }

    if (p?.birthDate && p?.birthPlace?.lon && p?.birthPlace?.lat) {
      Analytics.setUserProperty('birhchart_available', true);
    } else {
      Analytics.setUserProperty('birhchart_available', false);
    }

    try {
      await updateProfile(p);
      Analytics.trackEvent('profile', 'saved', { ..._pick(p, ['email', 'name', 'relStatus', 'birthDate', 'birthTime', 'birthPlace']) });
    }
    catch (error: any) {
      if (error?.status !== 422) {
        throw error;
      }
      console.log('[ERROR AUTH]', error);
    }
    finally {
      if (isSetPending) {
        dispatch(setPending(false));
      }
    }
  }
)

export const processGender = createAsyncThunk(
  `${profileSlice.name}/processGender`,
  async (gender: string, { dispatch }) => {
    Analytics.setUserProperty('gender', gender);
    Analytics.trackEvent('user', 'info', { gender });
    dispatch(setGender(gender));
  }
)

export const processBirthDate = createAsyncThunk(
  `${profileSlice.name}/processBirthDate`,
  async (date: string, { dispatch }) => {
    const age = birthDate2Age(date);

    Analytics.setUserProperty('age', age);
    Analytics.trackEvent('user', 'info', { birth_date: dayjs(date).format('YYYYMMDD'), age });

    dispatch(setBirthDate(date));
    dispatch(setAge(age));
  }
)

export const processRelStatus = createAsyncThunk(
  `${profileSlice.name}/processRelStatus`,
  async (status: string, { dispatch }) => {
    Analytics.setUserProperty('relStatus', status === 'broke_up' ? RELATIONSHIP.SINGLE : status);
    dispatch(setRelStatus(status));
  }
)

export const generateBestAdvisorsData = (): AppThunk => (dispatch, getState) => {
  const {
    profile: { advisorsData },
  } = getState();

  if (!advisorsData.length) {
    const data: AdvisorCardData[] = bestAdvisorsData.map(
      (advisor) => {
        return {
          ...advisor,
          matching: _random(minMatchingPercent, maxMatchingPercent),
        };
      }
    );
    dispatch(setAdvisorsData(_reverse(_sortBy(data, ['matching']))));
  }
};

export const processAdvisorSelection = (id: number): AppThunk => (dispatch, getState) => {
  const {
    profile: { selectedAdvisorId },
  } = getState();

  if (selectedAdvisorId !== id) {
    dispatch(setSelectedAdvisorId(id));
    Attribution.setURLBaseParams({ urlExtraPath: 'chat', query: { advisor_id: `${id}` } });
  }
};