import type { ImagesMap } from '@web-solutions/core/interfaces/images';

import PAUSE_1X from './pause@1x.webp'
import PAUSE_2X from './pause@2x.webp'
import PAUSE_3X from './pause@3x.webp'

export const IMAGES: ImagesMap = {
  PAUSE: {
    src: PAUSE_1X,
    srcSet: `${PAUSE_1X}, ${PAUSE_2X} 2x, ${PAUSE_3X} 3x`
  },
};