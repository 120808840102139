import { type FC, useEffect, useState, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import type { RemoteConfig } from '@web-solutions/core/constants/remote-config';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { t } from '@web-solutions/manage-subscription/src/localization'

import { goAppLink, pauseSubscription, useSliceDispatch } from '@web-solutions/manage-subscription/src/manage/slice'

import { ROUTES_V2 } from '@web-solutions/manage-subscription/src/manage/constants/routes';

import { Header } from '@web-solutions/manage-subscription/src/manage/components/header'
import { ErrorModal } from '@web-solutions/manage-subscription/src/manage/components/error-modal';

import { useManageNavigation } from '@web-solutions/manage-subscription/src/manage/hooks/use-manage-navigate'
import { useManageContext } from '@web-solutions/manage-subscription/src/manage/hooks/use-manage-context';

import { ANALYTICS, baseTKey } from './constants'

import { ActionButtons, Content, SuccessModal } from './components'

import { getFormattedPeriodsConfig } from './utils';

import type { FormattedPeriod } from './types';

import classes from './style.module.scss'

export const PauseSubscriptionPage: FC = () => {
  const location = useLocation()
  const navigate = useNavigate();

  const dispatch = useSliceDispatch();

  const { pauseSubscriptionConfig } = useManageContext()

  const { mngSubPausePage = {} } = useRemoteConfig() as RemoteConfig;

  const { mode, pausePeriodsInWeeks } = mngSubPausePage as Required<NonNullable<RemoteConfig['mngSubPausePage']>>

  const { navigatePrevSceen, navigateNextScreen } = useManageNavigation();

  const formattedPeriods = useMemo(() => getFormattedPeriodsConfig(pausePeriodsInWeeks), [pausePeriodsInWeeks])

  const [activePausePeriod, setActivePausePeriod] = useState(() => formattedPeriods[0])

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  useEffect(() => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.OPEN);
  }, [])

  const isNavigatedFromMain = location.state?.fromMain
  const currentMode = isNavigatedFromMain ? 'default' : mode

  const handleBackClick = () => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.BACK);

    if (isNavigatedFromMain) {
      navigate({
        pathname: ROUTES_V2.MAIN,
        search: location.search
      });
    } else {
      navigatePrevSceen();
    }
  }

  const handleGoToAppClick = () => {
    Analytics.trackEvent(ANALYTICS, 'return_to_app');

    dispatch(goAppLink());
  };

  const handlePauseSubClick = (period: FormattedPeriod) => () => {
    Analytics.trackEvent(ANALYTICS, 'pause_plan_click');

    dispatch(pauseSubscription(period.value))
      .unwrap()
      .then(() => {
        Analytics.trackEvent(`${ANALYTICS}_pause_plan`, EVENT_ACTION.SUCCESS, { periodsInDays: period.value });

        pauseSubscriptionConfig?.onSuccess?.()

        setIsSuccessModalOpen(true)
      })
      .catch((e: any) => {
        Analytics.trackEvent(`${ANALYTICS}_pause_plan`, EVENT_ACTION.ERROR, { message: e?.message, code: e?.code, });

        setIsErrorModalOpen(true)
      })
  }

  const handleContinueClick = () => {
    Analytics.trackEvent(ANALYTICS, 'continue');

    navigateNextScreen();
  };

  const handleChangeActivePausePeriod = (period: FormattedPeriod) => () => {
    Analytics.trackEvent(`${ANALYTICS}_pause_plan`, 'change_period', { periodsInDays: period.value });

    setActivePausePeriod(period)
  }

  const handleErrorClose = () => {
    setIsErrorModalOpen(false)
  }

  return (
    <div className={classes.pauseSubscriptionContainer}>
      <Header
        withBackButton
        title={t(`${baseTKey}.content.${currentMode}.${isNavigatedFromMain ? 'title_alt' : 'title'}`)}
        subtitle={t(`${baseTKey}.content.${currentMode}.subtitle`)}
        onBackClick={handleBackClick}
      />
      <Content
        mode={currentMode}
        periods={formattedPeriods}
        activePausePeriod={activePausePeriod}
        onChange={handleChangeActivePausePeriod}
      />
      <ActionButtons
        mode={currentMode}
        activePausePeriod={activePausePeriod}
        isNavigatedFromMain={isNavigatedFromMain}
        onContinueClick={handleContinueClick}
        onPauseSubClick={handlePauseSubClick}
        onBackClick={handleGoToAppClick}
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        category={`${ANALYTICS}_success_modal`}
        activePausePeriod={activePausePeriod}
        onClose={handleGoToAppClick}
      />
      <ErrorModal
        tKey='manage_v2.shared.error_modal'
        message={t('manage_v2.shared.error_modal.message')}
        isOpen={isErrorModalOpen}
        onCloseClick={handleErrorClose}
        onButtonClick={handleErrorClose}
      />
    </div >
  )
}
