import { useRemoteConfig } from "@web-solutions/core/hooks/use-remote-config"

import { useNavigateManageV2 } from "../v2/hooks/use-navigate-next-screen";

import { useNavigateManage } from "./use-navigate-next-screen";

export const useManageNavigation = () => {
  const { manageVersion } = useRemoteConfig();
  const isManageV1 = manageVersion !== 'v2';

  const {
    isPageAvailable: isPageAvailableV1,
    navigateNextScreen: navigateNextScreenV1,
    navigatePrevSceen: navigatePrevSceenV1
  } = useNavigateManage();
  const {
    isPageAvailable: isPageAvailableV2,
    navigateNextScreen: navigateNextScreenV2,
    navigatePrevSceen: navigatePrevSceenV2
  } = useNavigateManageV2();

  return {
    isPageAvailable: isManageV1 ? isPageAvailableV1 : isPageAvailableV2,
    navigateNextScreen: isManageV1 ? navigateNextScreenV1 : navigateNextScreenV2,
    navigatePrevSceen: isManageV1 ? navigatePrevSceenV1 : navigatePrevSceenV2
  }
}