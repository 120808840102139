import classnames from 'classnames';

import { Title, Text } from '@web-solutions/core/ui-elements';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { ReactComponent as ArrowBackIcon } from '../icons/arrow-back.svg';

import classes from './style.module.scss';

interface HeaderProps {
  title?: string | React.ReactNode,
  withBackButton?: boolean,
  subtitle?: string,
  content?: React.ReactNode,
  className?: string,
  titleClassName?: string,
  subtitleClassName?: string,
  withContent?: boolean,
  withLight?: boolean,
  onBackClick: () => void,
}

export const Header: React.FC<HeaderProps> = ({
  withBackButton,
  title,
  subtitle,
  content,
  className,
  titleClassName,
  subtitleClassName,
  withContent = true,
  withLight,
  onBackClick
}) => {
  const { manageVersion } = useRemoteConfig();

  return (
    <>
      <div className={classnames(className, classes.header)}>
        {withLight && <div className={classes.top_light} />}
        {withContent &&
          <>
            {title && <Title
              level="h1"
              className={classnames(
                titleClassName,
                classes.title, {
                [classes.top_gap]: withLight || manageVersion === 'v2'
              })}
            >
              {title}
            </Title>}
            {content}
            {subtitle && <Text className={classnames(subtitleClassName, classes.subtitle)}>{subtitle}</Text>}
          </>
        }
      </div>
      {withBackButton &&
        <button className={classes.back_button} onClick={onBackClick}>
          <ArrowBackIcon />
        </button>
      }
    </>
  )
}