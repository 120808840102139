import { type FC, useEffect } from 'react'

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { t } from '../../../localization';

import { Header } from '../../components/header';

import { useReturnToApp } from '../../../hooks';
import { useManageContext } from '../../hooks/use-manage-context';
import { useManageNavigation } from '../../hooks/use-manage-navigate';
import FixedButtonsInWrapper from '../../components/fixed-buttons-in-wrapper';

import { baseTKey, ANALYTICS } from './contants'

import classes from './style.module.scss';

export const DontMiss: FC = () => {
  const { navigatePrevSceen, navigateNextScreen } = useManageNavigation();
  const { dontMissConfig } = useManageContext();

  const { handleReturnAppClick: handleGoToApp } = useReturnToApp({ category: ANALYTICS });

  useEffect(() => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.OPEN);
  }, []);

  const handleBack = () => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.BACK);

    navigatePrevSceen();
  }

  const handleCancel = () => {
    Analytics.trackEvent(ANALYTICS, 'continue');

    navigateNextScreen();
  }

  return (
    <div className={classes.dontMissWrapper}>
      <Header
        withBackButton
        titleClassName={classes.title}
        onBackClick={handleBack}
      />
      {dontMissConfig?.content}

      <div className={classes.buttonsWrapper}>
        <FixedButtonsInWrapper
          buttonTitle={t(`${baseTKey}.go_app_btn`)}
          linkTitle={t(`${baseTKey}.cancel_btn`)}
          isFixedButton={false}
          isFixedLink={false}
          onButtonClick={handleGoToApp}
          onLinkClick={handleCancel}
        />
      </div>
    </div>
  )
}
