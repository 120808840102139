import request from 'core/utils/network';

const ASTROLOGY = '/astrology-questions';

export async function getAstrologersMinutesBalance() {
  return request.get(`${ASTROLOGY}/balance`);
}

export const getAstrologersData = async () => request.get(`${ASTROLOGY}/astrologers`)

export const applyPromocode = (promocode: string) => request.post(`${ASTROLOGY}/add-time`, { code: promocode })

export const checkPromocodeIsApplied = (promocode: string) => request.get(`${ASTROLOGY}/check-code?code=${promocode}`)