import React from 'react';
import classNames from 'classnames';

import { useWindowEnd } from '@web-solutions/core/hooks/use-window-end';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { Button, LinkButton } from '@web-solutions/core/ui-elements';

import classes from './style.module.scss';

interface Props {
  isFixedButton: boolean,
  isFixedLink?: boolean,
  buttonTitle: string,
  linkTitle: string,
  swapButtons?: boolean,
  onButtonClick: () => void,
  onLinkClick: () => void;
}

export const FixedButtonsInWrapper: React.FC<Props> = ({
  isFixedButton,
  isFixedLink,
  buttonTitle,
  linkTitle,
  swapButtons,
  onButtonClick,
  onLinkClick
}) => {
  const { isEnd } = useWindowEnd();

  const { manageVersion = 'v1', mngButtonsViewType } = useRemoteConfig();

  const isV2 = manageVersion === 'v2';

  const isSwapAllowed = isV2 ? mngButtonsViewType !== 'link' : true;
  return (
    <>
      <div className={classNames(classes.button_wrap, {
        [classes.hidden_button_wrap_fix_link]: isEnd && isFixedLink,
        [classes.static_buttons]: !isFixedButton,
        [classes.fix_button]: isFixedButton,
        [classes.hidden_button_wrap]: isEnd && !isFixedLink,
        [classes.fix_link]: isFixedButton && isFixedLink,
        [classes.swap_buttons]: swapButtons && isSwapAllowed,
      })}>
        {isV2 && mngButtonsViewType === 'link'
          ? <>
            <Button
              data-testid='manage-subscription-submit-button'
              onClick={onButtonClick}
              title={buttonTitle}
              className={classes.main_button}
              titleClassName={classes.main_button_title}
            />
            <LinkButton
              className={classes.link_button}
              onClick={onLinkClick}
            >
              {linkTitle}
            </LinkButton>
          </>
          :
          <>
            <Button
              data-testid='manage-subscription-submit-button'
              onClick={onButtonClick}
              title={buttonTitle}
              className={classes.main_button}
              titleClassName={classes.main_button_title}
            />
            <Button
              title={linkTitle}
              titleClassName={classes.bottom_button_title}
              className={classes.bottom_button}
              onClick={onLinkClick}
            />
          </>
        }
      </div>
      {isFixedButton && <div className={classes.gap} />}
    </>
  )
}


export default FixedButtonsInWrapper;