import classNames from 'classnames';

import { ProductDetails } from '@web-solutions/core/store/billing/selectors';
import ApplePay from '@web-solutions/core/payment/components/apple-pay';

import classes from '../../style.module.scss';

interface ApplePayContainerProps {
  activeProduct: ProductDetails;
  isButtonVisible: boolean;
  options: any;
  onApplePayClick: () => void;
  onApplePaySubmit: (formData: any) => void;
}

export const ApplePayContainer: React.FC<ApplePayContainerProps> = ({
  activeProduct,
  isButtonVisible,
  options,
  onApplePayClick,
  onApplePaySubmit,
}) => {

  return <>
    {
      isButtonVisible && (
        <div className={classes.buttonWrapper}>
          <ApplePay
            activeProduct={activeProduct}
            className={classes.appleButton}
            onApplePayClick={onApplePayClick}
            onApplePaySubmit={onApplePaySubmit}
          />
        </div>
      )
    }
    <div className={classNames(classes.buttonWrapper, { [classes.hidden]: !isButtonVisible })}>
      <div id={options?.applePayButtonParams?.containerId}></div>
    </div>
  </>
}