import { type FC } from 'react'
import classNames from 'classnames';

import type { RemoteConfig } from '@web-solutions/core/constants/remote-config';

import { Title } from '@web-solutions/core/ui-elements';

import { t } from '@web-solutions/manage-subscription/src/localization';

import { CheckboxDot } from '@web-solutions/manage-subscription/src/manage/components/checkbox-dot';

import { useManageContext } from '@web-solutions/manage-subscription/src/manage/hooks/use-manage-context';

import { baseTKey } from '../../constants';

import type { FormattedPeriod } from '../../types';

import classes from './style.module.scss'

interface ContentProps {
  mode: Required<NonNullable<RemoteConfig['mngSubPausePage']>>['mode'],
  periods: FormattedPeriod[],
  activePausePeriod: FormattedPeriod,
  onChange: (period: FormattedPeriod) => () => void
}

export const Content: FC<ContentProps> = ({ mode, periods, activePausePeriod, onChange }) => {
  const { pauseSubscriptionConfig } = useManageContext()

  return (
    <>
      {mode === 'with_features' ?
        (
          <>
            {pauseSubscriptionConfig?.content}
          </>
        ) :
        (
          <div className={classes.defaultContentWrapper}>
            <Title className={classes.title}>
              {t(`${baseTKey}.content.${mode}.list_title`)}
            </Title>
            {
              periods.map(({ titleKey, amount, value }) => (
                <CheckboxDot
                  key={value}
                  id={value.toString()}
                  isChecked={value === activePausePeriod.value}
                  label={
                    <span className={classNames(classes.label, { [classes.checked]: value === activePausePeriod.value })}>
                      {t(titleKey, { count: amount })}
                    </span>
                  }
                  onChange={onChange({ titleKey, amount, value })}
                />
              ))
            }
          </div>
        )
      }
    </>
  )
}
