import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Text, Title, Button } from '@web-solutions/core/ui-elements';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { t, tm } from '../../../../../localization';
import { SubscriptionStatus, SubscriptionState } from '../../../../types';

import { selectProduct, selectSubscription } from '../../../../slice';

import classes from './style.module.scss';

const tKey = 'manage.current_plan';

interface CurrentPlanProps {
  onMoreClick: () => void;
  onChangePlanClick: () => void;
  onReactivateClick: () => void;
}

export const CurrentPlan: React.FC<CurrentPlanProps> = ({
  onMoreClick,
  onChangePlanClick,
  onReactivateClick,
}) => {

  const subscription = useSelector(selectSubscription);
  const product = useSelector(selectProduct);

  const { mngSubMainPage: config } = useRemoteConfig();

  const isEditBtnEnabled = config.editBtnEnabled;

  if (!subscription || !product) {
    return null;
  }

  const isCanceled = subscription.status === SubscriptionStatus.CANCELED || subscription.status === SubscriptionStatus.EXPIRED

  const handleMoreClick = () => {
    onMoreClick();
    Analytics.trackEvent('manage_main_more', EVENT_ACTION.CLICK);
  }

  return (
    <div className={classNames(classes.wrap)}>
      <div className={classes.headerWrapper}>
        <Title
          level='h2'
          className={classes.title}
        >
          {t(`${tKey}.title`)}
        </Title>
      </div>

      <div className={classNames(classes.status_badge, {
        [classes.canceled]: isCanceled
      })}>
        {subscription.state === SubscriptionState.PAUSED ? t(`manage.status.paused`) : t(`manage.status.${subscription.status}`)}
      </div>
      {!isCanceled && <button onClick={handleMoreClick} className={classes.more_button}>
        <div className={classes.dot} />
        <div className={classes.dot} />
        <div className={classes.dot} />
      </button>}
      <div className={classes.content_container}>
        <div className={classNames(classes.row)}>
          <Text className={classes.left}>{t(`${tKey}.price`)}</Text>
          <div className={classes.dots} />
          <Text className={classes.right}>{tm(config.currentPlanPrice, '', { ...product })}</Text>
        </div>
        {product.period && (
          <div className={classes.row}>
            <Text className={classes.left}>{t(`${tKey}.period`)}</Text>
            <div className={classes.dots} />
            <Text className={classes.right}>{t(`manage.period.${product.period}`)}</Text>
          </div>
        )}
      </div>
      {isEditBtnEnabled &&
        <div className={classes.change_plan_btn_wrap}>
          {isCanceled ?
            <Button
              onClick={onReactivateClick}
              className={classes.change_plan_btn}
              titleClassName={classes.change_plan_btn_title}
              title={t(`manage.main.reactivate_btn`)}
            />
            :
            <Button
              onClick={onChangePlanClick}
              className={classes.change_plan_btn}
              titleClassName={classes.change_plan_btn_title}
              title={t(`${tKey}.change_plan_btn`)}
            />}
        </div>
      }
    </div>
  );
};
