import { useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { Button, Title } from "@web-solutions/core/ui-elements"
import { PaymentSystem } from "@web-solutions/react-billing";
import { selectSubscription, selectPaymentSystem } from '@web-solutions/manage-subscription/src/manage/slice';
import Analytics from '@web-solutions/module-analytics';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import { ContactUsModal } from '@web-solutions/core/components/contact-us-modal';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { T, t } from '../../../../../localization';

import classes from '../style.module.scss';
import { useManageContext } from '../../../../hooks/use-manage-context';

interface FooterProps {
  tKey: string;
  onChangePlanClick: () => void;
  onCancelSubscriptionClick: () => void;
  onReturnToAppClick: () => void;
  withCancel?: boolean;
}

export const Footer: React.FC<FooterProps> = ({
  tKey,
  withCancel = true,
  onChangePlanClick,
  onCancelSubscriptionClick,
  onReturnToAppClick
}) => {

  const subscription = useSelector(selectSubscription);
  const paymentSystem = useSelector(selectPaymentSystem);
  const { fastCancelMode } = useManageContext();
  const { mngSubMainPage } = useRemoteConfig();

    const [isOpened, setIsOpened] = useState(false)
  
    const handleOpenModal = () => {
      setIsOpened(true)
    }
  
    const handleCloseModal = () => {
      setIsOpened(false)
    }

  const handleClick = (type: string) => () => {
    Analytics.trackEvent('manage_main_footer', EVENT_ACTION.CLICK, { type });
    switch (type) {
      case 'change':
        onChangePlanClick();
        break;
      case 'cancel':
        onCancelSubscriptionClick();
        break;
      case 'support':
        handleOpenModal();
        break;
    }
  };

  return (
    <>
      {subscription
        ? (
          <>
            {(paymentSystem === PaymentSystem.SOLIDGATE || paymentSystem === PaymentSystem.RECURLY) && !fastCancelMode &&
              <div className={classes.text}>
                <T
                  k={`manage_v2.main.footer.change`}
                  components={{
                    span: <p onClick={handleClick('change')}
                      className={classnames(classes.link, { [classes.colored]: mngSubMainPage.isColoredFooterLinks })}
                    />
                  }}
                />
              </div>
            }
           {withCancel && <div className={classes.text}>
              <T
                k={`manage_v2.main.footer.cancel`}
                components={{
                  span: <p onClick={handleClick('cancel')}
                    className={classnames(classes.link, { [classes.colored]: mngSubMainPage.isColoredFooterLinks })}
                  />
                }}
              />
            </div>}
            <div className={classes.text}>
              <T
                k={`manage_v2.main.footer.support`}
                components={{
                  span: <p onClick={handleClick('support')}
                    className={classnames(classes.link, { [classes.colored]: mngSubMainPage.isColoredFooterLinks })}
                  />
                }}
              />
            </div>
          </>
        )
        : (
          <>
            <Title level={'h1'}>{t(`${tKey}.no_subscription`)}</Title>
            <Button
              onClick={onReturnToAppClick}
              className={classes.btn}
              title={t(`${tKey}.return_to_app`)}
            />
          </>
        )}
      <ContactUsModal
        active={isOpened}
        openLocation='manage_sub'
        onCloseModal={handleCloseModal}
        onCloseModalAfterSubmit={handleCloseModal}
      />
    </>
  )

}