import { useSelector } from 'react-redux';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import { selectProducts } from '@web-solutions/core/store/billing/selectors';
import { selectProduct } from '@web-solutions/manage-subscription/src/manage/slice';

export const useOfferWithBonus = () => {
  const { mngOfferWithBonusPage } = useRemoteConfig();
  const { title, btnText, skipBtnText } = mngOfferWithBonusPage;
  const currentProduct = useSelector(selectProduct);
  const products = useSelector(selectProducts);

  const product = mngOfferWithBonusPage[currentProduct?.currency!] ?? mngOfferWithBonusPage.USD;
  const productId = product?.productId!;

  const offer = products?.find((product) => product.id === productId);


  return {
    offer,
    productId,
    title,
    btnText,
    skipBtnText,
  };
};