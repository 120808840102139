import { useDispatch } from 'react-redux';
import { type FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import i18next from 'i18next';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { useScrollOnFocus } from '@web-solutions/core/hooks/use-scroll-on-focus';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { t } from '../../../localization';

import { Header } from '../../components/header';

import { useManageNavigation } from '../../hooks/use-manage-navigate';
import { setHasBeenOnReviewStep } from '../../slice';

import ResultPopUp from './components/result-popup';

import { baseTKey, ANALYTICS } from './contants'

import { Form } from './components'

import { useUpdateWrapperSize } from './hooks'

import classes from './style.module.scss';

export const Review: FC = () => {
  const dispatch = useDispatch();
  const { navigatePrevSceen, navigateNextScreen } = useManageNavigation();
  const { manageWithReviewStep, manageVersion } = useRemoteConfig();

  const minimumCharactersForModal = i18next.language === 'ja' ? 10 : 20;

  const [isOpen, setIsOpen] = useState(false);
  const [reviewText, setReviewText] = useState('');

  useScrollOnFocus();

  const ref = useUpdateWrapperSize<HTMLDivElement>({ maxHeight: 'initial', minHeight: '100svh' });

  useEffect(() => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.OPEN);
  }, []);

  const handleBackClick = () => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.BACK);
    navigatePrevSceen();
  }

  const handleSubmit = (text: string) => {
    if (manageWithReviewStep.enabledPopup && text.length >= minimumCharactersForModal) {
      setReviewText(text);
      setIsOpen(true);
    } else {
      dispatch(setHasBeenOnReviewStep(true));
      navigateNextScreen();
    }
  }

  const handleClose = () => {
    dispatch(setHasBeenOnReviewStep(true));
    setIsOpen(false);
    navigateNextScreen();
  }

  return (
    <div ref={ref} className={classnames(classes.reviewWrapper, { [classes.v2]: manageVersion === 'v2' })}>
      <Header
        withBackButton
        title={t(`${baseTKey}.title`)}
        subtitle={t(`${baseTKey}.subtitle`)}
        titleClassName={classes.title}
        onBackClick={handleBackClick}
      />
      <Form onSubmit={handleSubmit} />
      {manageWithReviewStep.enabledPopup &&
        <ResultPopUp
          isOpen={isOpen}
          onClose={handleClose}
          text={reviewText}
        />
      }
    </div>
  )
}
