import { useContext, createContext } from 'react';

export interface ManageContext {
  defaultCancelSuccessContent?: React.ReactNode,
  cancelSuccessContent?: React.ReactNode,
  emailCancelContent?: React.ReactNode,
  freeGiftCancelContent?: React.ReactNode,
  successModalContent?: React.ReactNode,
  benefitsContent?: React.ReactNode,
  offerWithBonusContent?: {
    renderContent: (v: boolean) => React.ReactNode,
    category: string,
    tKey: string,
  }
  currentPlanContent?: React.ReactNode,
  offerCheapProductContent?: {
    title?: React.ReactNode,
    content?: React.ReactNode,
  }
  infoConfig?: {
    contactUsWithModal?: boolean
  }
  offerFreePeriodContent?: React.ReactNode,
  withFarewellOffer?: boolean;
  farewellOfferContent?: React.ReactNode,
  terminateFeatures?: React.ReactNode,
  progressContent?: React.ReactNode,
  mainContent?: React.ReactNode,
  featuresSection?: React.ReactNode,
  featuresTile?: React.ReactNode,
  dontMissConfig?: {
    content?: React.ReactNode,
    enabled?: boolean,
  }
  plansWithCloseIcon?: boolean;
  logo?: React.ReactNode;
  fastCancelMode?: boolean;
  withFeatures?: boolean;
  comingSoonModalContent?: React.ReactNode;
  pauseSubscriptionConfig?: {
    enabled?: boolean,
    content?: React.ReactNode,
    onSuccess?: () => void,
  };
}

const manageContext = createContext({} as ManageContext);
export const ManageContextProvider = manageContext.Provider

export const useManageContext = () => {
  const context = useContext(manageContext);
  return context;
}