import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Navigate, Routes, Route } from 'react-router-dom';

import { Preloader } from '@web-solutions/core/ui-elements';

import { useSetKeyboardInsetHeightVar } from '@web-solutions/core/hooks/use-set-keyboard-inset-height-var';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { selectPending, selectReady, init, useSliceDispatch } from './slice';

import { ROUTES, ROUTES_V2 } from './constants/routes';

import { CommonWrapper } from './components/common-wrapper'

import { ManageMainPage } from './screens/main';
import { SelectReasonScreen } from './screens/reason';
import { ManagePlansPage } from './screens/plans';
import { CancelSuccessPage } from './screens/cancel-success';
import { OfferCheapProductPage } from './screens/offer-cheap-product';
import { OfferFreePeriodPage } from './screens/offer-free-period';
import { FarewellOfferPage } from './screens/farewell-offer';
import { ManageBenefitsPage } from './screens/benefits';
import { TerminateSubscription } from './screens/terminate';
import { ProgressPage } from './screens/progress';
import { EmailCancelPage } from './screens/email-cancel';
import { FreeGiftCancelPage } from './screens/free-gift-cancel';
import { ManageSurvey } from './screens/survey';
import { Review } from './screens/review';
import { DontMiss } from './screens/dont-miss';

import { ManageContext, ManageContextProvider } from './hooks/use-manage-context';
import { ManageV2MainPage } from './v2/screens/main';
import { ManageV2BenefitsPage } from './v2/screens/benefits';
import { SelectReasonScreenV2 } from './v2/screens/reason';
import { PauseSubscriptionPage } from './v2/screens/pause-subscription';

import classes from './style.module.scss';
import { OfferWithBonus } from './v2/screens/offer-with-bonus';

const SCREENS_V2 = {
  [ROUTES_V2.MAIN]: <ManageV2MainPage />,
  [ROUTES_V2.REASON]: <SelectReasonScreenV2 />,
  [ROUTES_V2.PLANS]: <ManagePlansPage />,
  [ROUTES_V2.BENEFITS]: <ManageV2BenefitsPage />,
  [ROUTES_V2.OFFER_CHEAP_PRODUCT]: <OfferCheapProductPage />,
  [ROUTES_V2.OFFER_FREE_PERIOD]: <OfferFreePeriodPage />,
  [ROUTES_V2.SINGLE_OFFER_CHEAP_PRODUCT]: <OfferCheapProductPage />,
  [ROUTES_V2.CANCEL_SUCCESS]: <CancelSuccessPage />,
  [ROUTES_V2.EMAIL_CANCEL]: <EmailCancelPage />,
  [ROUTES_V2.REVIEW]: <Review />,
  [ROUTES_V2.DONT_MISS]: <DontMiss />,
  [ROUTES_V2.PAUSE]: <PauseSubscriptionPage />,
  [ROUTES_V2.OFFER_WITH_BONUS]: <OfferWithBonus />
}

const SCREENS = {
  [ROUTES.MAIN]: <ManageMainPage />,
  [ROUTES.REASON]: <SelectReasonScreen />,
  [ROUTES.PLANS]: <ManagePlansPage />,
  [ROUTES.BENEFITS]: <ManageBenefitsPage />,
  [ROUTES.OFFER_CHEAP_PRODUCT]: <OfferCheapProductPage />,
  [ROUTES.SINGLE_OFFER_CHEAP_PRODUCT]: <OfferCheapProductPage />,
  [ROUTES.OFFER_FREE_PERIOD]: <OfferFreePeriodPage />,
  [ROUTES.FAREWELL_OFFER]: <FarewellOfferPage />,
  [ROUTES.CANCEL_SUCCESS]: <CancelSuccessPage />,
  [ROUTES.PROGRESS]: <ProgressPage />,
  [ROUTES.TERMINATE_SUBSCRIPTION]: <TerminateSubscription />,
  [ROUTES.EMAIL_CANCEL]: <EmailCancelPage />,
  [ROUTES.FREE_GIFT_CANCEL]: <FreeGiftCancelPage />,
  [ROUTES.SURVEY]: <ManageSurvey />,
  [ROUTES.REVIEW]: <Review />,
  [ROUTES.DONT_MISS]: <DontMiss />
};

interface ManageProps extends ManageContext {
  isApiReady: boolean,
  isLoadedApp: boolean,
}

export const Manage: React.FC<ManageProps> = ({
  isApiReady,
  isLoadedApp,
  infoConfig = { contactUsWithModal: true },
  ...manageContextValues
}) => {
  const dispatch = useSliceDispatch();

  const isPending = useSelector(selectPending);
  const isReady = useSelector(selectReady);

  const { manageVersion = 'v1' } = useRemoteConfig();

  useSetKeyboardInsetHeightVar();

  useEffect(() => {
    if (isApiReady && isLoadedApp) {
      dispatch(init({ actualized: true }));
    }
  }, [dispatch, isApiReady, isLoadedApp]);

  if (!isReady) {
    return <Preloader withText className={classes.loader} />
  }

  return (
    <>
      {(isPending)
        ? <Preloader />
        : null}
      <Suspense fallback={<div />}>
        <ManageContextProvider value={{ infoConfig, ...manageContextValues }}>
          <BrowserRouter>
            <CommonWrapper>
              <Routes>
                {manageVersion === 'v2' && Object.values(ROUTES_V2).map((r, i) => (
                  <Route key={i} path={r} element={SCREENS_V2[r]} />
                ))}
                {manageVersion === 'v1' && Object.values(ROUTES).map((r, i) => (
                  <Route key={i} path={r} element={SCREENS[r]} />
                ))}
                <Route path={'*'} element={
                  <Navigate to={{
                    pathname: ROUTES.MAIN,
                    search: window.location.search,
                  }} />}
                />
              </Routes>
            </CommonWrapper >
          </BrowserRouter>
        </ManageContextProvider>
      </Suspense>
    </>
  );
}
