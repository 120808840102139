import classNames from 'classnames';

import { Checkbox } from '@web-solutions/core/ui-elements';

import classes from './style.module.scss';

interface Props {
  status?: boolean | null;
  className?: string;
}

export const TermsCheckBox: React.FC<Props> = ({ status, className }) => {

  return (
    <div className={classNames(classes.checkboxWrapper, className, { [classes.error]: status === false })}>
      <Checkbox
        className={classes.checkboxInput}
        classNameCheckbox={classes.checkboxSpan}
        isChecked={!!status}
      />
    </div>
  )
}