import React from 'react';
import classNames from 'classnames';
import { Textfit } from 'react-textfit'

import { tm, TM } from '@web-solutions/module-localization';

import { ProductDetails } from '@web-solutions/core/store/billing/selectors';
import TrialsLeft, { TrialsLeftType } from '@web-solutions/core/payment/components/trials-left';

import { DiscountWithTimer } from '../discount-with-timer';
import { SmallBadge } from '../small-badge';
import { SoldLine } from '../sold-line';

import classes from './style.module.scss';

const tKey = 'core.plans';

interface ItemProps {
  isActive: boolean,
  product: ProductDetails,
  onClick: (product: ProductDetails) => void,
  titleText: string,
  subTitleText?: string,
  perText: string,
  perTitle: string,
  className?: string,
};

export const Item: React.FC<ItemProps> = ({
  isActive,
  product,
  onClick,
  titleText,
  subTitleText,
  perText,
  perTitle,
  className,
}) => {
  const {
    period,
    isTrial,
    textLineThrough,
    accentTextLineThrough,
    sold,
    trialsLeftBadge,
  } = product;

  const afterTrialText = tm(
    product.afterTrialText,
    `${tKey}.after_trial.${period}`,
    {
      ...product,
      interpolation: { escapeValue: false, },
    });

  const handleItemClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(product);
  };

  const isSoldOut = !!sold?.total && !sold.left;

  const isLightColorPerWeek = !!accentTextLineThrough && !isActive;

  return (
    <div className={classNames(classes.wrap, { [classes.disabled]: isSoldOut })}>
      {trialsLeftBadge && <TrialsLeft type={TrialsLeftType.BADGE_PRODUCT} />}
      <DiscountWithTimer
        discount={product.discount}
        badgeTitle={tm(product.badgeTitle, isSoldOut ? 'core.plans.sold_out' : '')}
        centered={product.badgeCentered}
        disabled={isSoldOut}
        className={className}
      />
      <button
        type="button"
        onClick={handleItemClick}
        className={classNames(classes.container, { [classes.isActiveContainer]: isActive })}
        disabled={isSoldOut}
      >
        <div className={classes.detailWrap}>
          <p className={classes.productTitle}>
            {titleText}
            <SmallBadge
              texts={product.smallBadges}
              disabled={isSoldOut}
            />
          </p>
          {!sold?.hiddenSubTitles ?
            (
              <>
                <p className={classes.productSubTitle}>
                  {subTitleText && <TM k={subTitleText} defaultKey='' tOptions={product} />}
                  &nbsp;
                  {textLineThrough && <span className={classes.lineThrough}>{tm(textLineThrough, '', { ...product })}</span>}
                </p>
                {isTrial && (
                  <p className={classes.afterTrialText}>
                    {afterTrialText}
                  </p>
                )}
              </>
            )
            : null}
          {sold?.total ?
            (
              <SoldLine
                isActive={isActive}
                left={sold.left}
                total={sold.total}
              />
            )
            : null}
        </div>
        <div className={classes.accentWrap}>
          {accentTextLineThrough
            ?
            <Textfit mode='single' min={15} max={17} className={classes.accentTextLineThrough}>
              {tm(accentTextLineThrough, '', { ...product })}
            </Textfit>
            :
            null
          }
          <Textfit mode='single' min={17} max={20} className={classes.accentTitle}>
            {perTitle}
          </Textfit>
          <p className={classNames(classes.accentSubTitle, isLightColorPerWeek && !isActive && classes.lightAccentSubTitle)}>
            {perText}
          </p>
        </div>
      </button>
    </div>
  );
};
