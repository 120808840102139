import { type FC } from 'react'
import dayjs from 'dayjs'

import { Image } from '@web-solutions/core/ui-elements';

import { t, T } from '@web-solutions/manage-subscription/src/localization';

import { SuccessModal as ManageSuccessModal, SuccessModalProps as ManageSuccessModalProps } from '@web-solutions/manage-subscription/src/manage/components/success-modal';

import { baseTKey } from '../../constants';

import type { FormattedPeriod } from '../../types';

import { IMAGES } from './images'

import classes from './style.module.scss'

const tKey = `${baseTKey}.success_modal`

interface SuccessModalProps extends Omit<ManageSuccessModalProps, 'tKey' | 'title' | 'message'> {
  activePausePeriod: FormattedPeriod
}

export const SuccessModal: FC<SuccessModalProps> = ({ activePausePeriod, ...props }) => (
  <ManageSuccessModal
    tKey={tKey}
    img={<Image className={classes.img} img={IMAGES.PAUSE} alt='pause' />}
    message={<T k={`${tKey}.content`} tOptions={{ date: dayjs().add(activePausePeriod.value, 'days').format('MMMM D, YYYY'), period: t(activePausePeriod.titleKey, { count: activePausePeriod.amount }) }} />}
    {...props}
  />
)
