import React, { useEffect } from 'react';

import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import FixedButtonsInWrapper from '../fixed-buttons-in-wrapper';

import { useSliceDispatch } from '../../slice';

import { Header } from '../../components/header';

import { useManageNavigation } from '../../hooks/use-manage-navigate';

import { t } from '../../../localization';

import { goAppLink } from '../../slice';

import classes from './style.module.scss';

const tKey = "manage.progress"

interface ProgressWrapperProps {
  children: React.ReactNode,
  onSubmit: () => void;
  category: string,
  fixedButton?: boolean,
  fixedLink?: boolean,
}

export const ProgressWrapper: React.FC<ProgressWrapperProps> = ({ children, category, fixedButton = false, fixedLink = false, onSubmit }) => {
  const dispatch = useSliceDispatch();
  const { navigatePrevSceen } = useManageNavigation();

  useEffect(() => {
    Analytics.trackEvent(category, 'open');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    Analytics.trackEvent(`${category}_back`, EVENT_ACTION.CLICK);
    navigatePrevSceen();
  }

  const handleSubmitClick = () => {
    Analytics.trackEvent(`${category}_submit`, 'submit');
    onSubmit();
  }

  const handleKeepClick = () => {
    Analytics.trackEvent(`${category}_keep`, EVENT_ACTION.CLICK);
    dispatch(goAppLink())
  }

  return (
    <div className={classes.wrap}>
      <Header
        className={classes.header}
        titleClassName={classes.header_title}
        title={t(`${tKey}.title`)}
        subtitle={t(`${tKey}.subtitle`)}
        onBackClick={handleBack}
        withBackButton
      />
      {children}
      <footer>
        <FixedButtonsInWrapper
          isFixedButton={fixedButton}
          isFixedLink={fixedLink}
          buttonTitle={t(`${tKey}.submit_button`)}
          linkTitle={t(`${tKey}.keep_button`)}
          onButtonClick={handleSubmitClick}
          onLinkClick={handleKeepClick}
        />
      </footer>
    </div>
  );
};