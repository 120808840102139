import { useEffect, useState } from 'react'
import _debounce from 'lodash/debounce'

import { checkIsBodyFixed } from '@web-solutions/core/utils/check-is-body-fixed';

export const useGetIsScrolled = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const rootEl = (checkIsBodyFixed() ? document.getElementById('root') : document.body)!

    const handleScroll = () => {
      setIsScrolled(rootEl?.scrollTop > 10);
    }

    rootEl.addEventListener('scroll', handleScroll);

    return () => {
      rootEl.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return isScrolled;
}
