import React from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

interface CheckboxDotProps {
  id: string;
  isChecked: boolean;
  label: React.ReactNode;
  classNameCheckbox?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxDot: React.FC<CheckboxDotProps> = ({ id, isChecked, onChange, classNameCheckbox, label }) => (
  <div className={classes.checkbox}>
    <label htmlFor={id} className={classNames(classes.label, { [classes.checked]: isChecked })}>
      <div className={classes.checkbox__wrapper}>
        <input type="checkbox" id={id} checked={isChecked} onChange={onChange} />
        <span className={classNames(classes.checkbox__mark, classNameCheckbox)} />
      </div>
      {label}
    </label>
  </div>
);

export default CheckboxDot;