import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Title } from '@web-solutions/core/ui-elements';
import ErrorPopup from '@web-solutions/core/payment/components/error-popup';
import { useGetUrl } from '@web-solutions/manage-subscription/src/manage/screens/main/hooks/use-get-url';

import { t } from '../../../../localization';

import { selectSubscription, useSliceDispatch, goAppLink, reactivate } from '../../../slice';
import { ROUTES, ROUTES_V2 } from '../../../constants/routes';
import { ReactComponent as ArrowBackIcon } from '../../../components/icons/arrow-back.svg';
import { useManageContext } from '../../../hooks/use-manage-context';
import { SubscriptionStatus } from '../../../types';
import { useUnsubscribe } from '../../../hooks/use-unsubscribe';
import { ComingSoonModal } from '../../../components/coming-soon-modal';
import { useManageNavigation } from '../../../hooks/use-manage-navigate';
import { SuccessModal } from '../../../components/success-modal';

import {
  tKey,
  ANALYTICS_MAIN,
} from './constants'

import { CurrentPlan } from './current-plan';
import { Features } from './features';

import classes from './style.module.scss';
import { Footer } from './footer';
import { InfoModal } from './info-modal';

import '@web-solutions/core/containers/page-transition/styles.scss'

export const ManageV2MainPage: React.FC = () => {
  const dispatch = useSliceDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { fastCancelMode, withFeatures = true } = useManageContext();

  const { navigateNextScreen } = useManageNavigation();

  const settingsUrl = useGetUrl();

  const { cancelSub, setErrorMessage, errorMessage } = useUnsubscribe(ANALYTICS_MAIN);

  const subscription = useSelector(selectSubscription);

  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [comingSoonModalOpen, setComingSoonModalOpen] = useState(false);

  const [success, setSuccess] = useState(false);
  const [statusCode, setStatusCode] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    Analytics.trackEvent(ANALYTICS_MAIN, EVENT_ACTION.OPEN);
  }, []);

  const handleChangePlanClick = () => {
    Analytics.trackEvent(`${ANALYTICS_MAIN}_change_plan`, EVENT_ACTION.CLICK);
    navigate({
      pathname: ROUTES.PLANS,
      search: location.search,
    },
      {
        state: {
          fromMain: true,
        }
      });
  };

  const handleCancelSubscriptionClick = () => {
    if (fastCancelMode) {
      Analytics.trackEvent(ANALYTICS_MAIN, 'continue', { unsubscribe: true });
      cancelSub();
    } else {
      Analytics.trackEvent(ANALYTICS_MAIN, 'continue');
      navigateNextScreen();
    }
  };

  const handlePauseSubscriptionClick = () => {
    navigate(
      {
        pathname: ROUTES_V2.PAUSE,
        search: location.search,
      },
      { state: { fromMain: true, } }
    );
  };

  const handleReturnToAppClick = () => {
    Analytics.trackEvent(ANALYTICS_MAIN, 'return_to_app');
    dispatch(goAppLink());
  };

  const handleCloseErrorClick = () => {
    Analytics.trackEvent(`${ANALYTICS_MAIN}_error_modal`, 'close');
    setErrorMessage('');
  }

  const handleBackClick = () => {
    Analytics.trackEvent(ANALYTICS_MAIN, 'back_to_app');
    window.location.href = settingsUrl;
  }

  const handleMoreClick = () => {
    setInfoModalOpen(true);
  }

  const handleCloseInfoModal = () => {
    setInfoModalOpen(false);
  }

  const handlePaymentMethodChange = () => {
    setComingSoonModalOpen(true);
  }

  const handleComingSoonModalClose = () => {
    setInfoModalOpen(false);
    setComingSoonModalOpen(false);
  }
  const handleReactivate = () => {
    Analytics.trackEvent('manage_reactivate', EVENT_ACTION.SUBMIT, { productId: subscription?.product });
    dispatch(reactivate())
      .unwrap()
      .then(() => {
        Analytics.trackEvent('manage_reactivate', EVENT_ACTION.SUCCESS, { productId: subscription?.product });
        setSuccess(true);
      })
      .catch((e) => {
        Analytics.trackEvent('manage_reactivate', EVENT_ACTION.ERROR, { productId: subscription?.product, code: e?.code, message: e?.message });
        setError(e?.message || t('manage.error.text'));
        setStatusCode(e?.code)
      });
  }

  const isActiveSub = !!subscription && !(subscription?.status === SubscriptionStatus.CANCELED || subscription?.status === SubscriptionStatus.EXPIRED)

  return (
    <>
      <button className={classes.back_button} onClick={handleBackClick}>
        <ArrowBackIcon />
      </button>
      <div className={classes.wrap}>
        <Title level='h1'>
          {t(`${tKey}.title`)}
        </Title>
        {Boolean(subscription) && (
          <CurrentPlan
            onMoreClick={handleMoreClick}
            onReactivateClick={handleReactivate}
            onChangePlanClick={handleChangePlanClick}
          />
        )}
        {withFeatures && (
          <Features />
        )}
        <Footer
          tKey={tKey}
          withCancel={isActiveSub}
          onCancelSubscriptionClick={handleCancelSubscriptionClick}
          onChangePlanClick={handleChangePlanClick}
          onReturnToAppClick={handleReturnToAppClick}
        />
      </div>
      <SuccessModal
        category="manage_reactivate_success_modal"
        tKey="manage.plan.reactivated_modal"
        isOpen={success}
      />
      <ErrorPopup
        visible={!!error || !!errorMessage}
        tKey={errorMessage ? 'manage.terminate_subscription.error_modal' : undefined}
        buttonTitle='OK'
        statusCode={statusCode}
        onClose={handleCloseErrorClick}
        onSubmit={handleCloseErrorClick}
      />
      <ComingSoonModal
        isOpen={comingSoonModalOpen}
        category={`${ANALYTICS_MAIN}_coming_soon`}
        onClose={handleComingSoonModalClose}
      />
      <InfoModal
        visible={infoModalOpen}
        onPaymentMethodChange={handlePaymentMethodChange}
        onClose={handleCloseInfoModal}
        onChange={handleChangePlanClick}
        onCancel={handleCancelSubscriptionClick}
        onPause={handlePauseSubscriptionClick}
      />
    </>
  );
};
