import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import intersection from 'lodash/intersection';

import { InjectedPaymentMethod } from 'core/constants/remote-config';
import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { setInjectedPaymentMethod } from 'core/store/billing/actions';
import { useShowPayPal, useShowApplePay, useShowGooglePay, useShowUPI } from 'core/hooks/use-show-payment-method';

import { selectInjectedPaymentMethod } from 'core/store/billing/selectors';

import { ReactComponent as Check } from './icons/check.svg';

import classes from './style.module.scss';

interface Props {
  isBaseSwitch?: boolean;
}

const InjectedPaymentMethodSwitch: React.FC<Props> = ({ isBaseSwitch = false }) => {
  const { defaultInjectedPaymentMethod, injectedPaymentMethods, theme } = useRemoteConfig();
  const injectedPaymentMethod = useSelector(selectInjectedPaymentMethod);

  const dispatch = useDispatch();
  const showPayPal = useShowPayPal();
  const showApplePay = useShowApplePay();
  const showGooglePay = useShowGooglePay();
  const showUPI = useShowUPI();

  const methods = {
    [InjectedPaymentMethod.UPI]: { show: showUPI },
    [InjectedPaymentMethod.MERCADO]: { show: true },
    [InjectedPaymentMethod.APPLEPAY]: { show: showApplePay },
    [InjectedPaymentMethod.PAYPAL]: { show: showPayPal },
    [InjectedPaymentMethod.CARD]: { show: true },
    [InjectedPaymentMethod.GOOGLEPAY]: { show: showGooglePay },
  };

  useEffect(() => {
    if (!injectedPaymentMethod) {
      dispatch(setInjectedPaymentMethod(defaultInjectedPaymentMethod));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const availableMethods = injectedPaymentMethods.map((item) => item.filter((id) => methods[id].show !== false));
    const isIntersect = !!intersection(availableMethods.flat(), defaultInjectedPaymentMethod).length;
    if (!isIntersect) {
      dispatch(setInjectedPaymentMethod(availableMethods[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showApplePay, showPayPal, showGooglePay, defaultInjectedPaymentMethod, dispatch, injectedPaymentMethods])

  const handleButtonClick = (id: InjectedPaymentMethod[]) => {
    dispatch(setInjectedPaymentMethod(id));
  }

  const getButton = (id: InjectedPaymentMethod) => {
    switch (id) {
      default:
        return (
          <div className={classes.imgContainer}>
            {!isBaseSwitch && <div className={classes[`${id}-dark`]} />}
            <div className={
              classNames(
                isBaseSwitch ? classes[`${id}-base`] : classes[id],
                { [classes.light_theme]: theme.mode === 'light' }
              )}
            />
          </div>
        );
    }
  }

  return (
    <div className={classNames(classes.wrapper, isBaseSwitch && classes.base)}>
      {
        injectedPaymentMethods.map((paymentMethods, index) => {
          const availablePaymentMethods = paymentMethods.filter(methodId => methods[methodId].show)
          const show = paymentMethods.reduce((acc, item) => (acc = methods[item].show || acc), false);

          if (!show) {
            return null;
          }

          return <div
            key={index}
            className={classNames(classes.method, { [classes.active]: isEqual(injectedPaymentMethod, paymentMethods) })}
            onClick={() => handleButtonClick(paymentMethods)}
          >
            <div className={classes.check}>
              <Check className={classes.icon} />
            </div>
            <div className={classes.container}>
              {availablePaymentMethods.map((methodId) => (getButton(methodId)))}
            </div>
          </div>
        })
      }
    </div>
  );
}

export default InjectedPaymentMethodSwitch;
