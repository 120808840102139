import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Preloader } from '@web-solutions/core/ui-elements';

import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { TermsBlock } from '@web-solutions/core/containers/terms-block';
import { selectIsTermsAccepted } from '@web-solutions/core/store/billing/selectors';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { Header } from '../../../components/header';

import { OperationModals } from '../../../components/operation-modals';

import { TM, tm } from '../../../../localization';

import { useManageContext } from '../../../hooks/use-manage-context';

import FixedButtonsInWrapper from '../../../components/fixed-buttons-in-wrapper';
import { useManageNavigation } from '../../../hooks/use-manage-navigate';

import { useOfferWithBonus } from '../../hooks/use-offer-with-bonus';

import { useChangePlan } from '../../../hooks/use-change-plan';

import classes from './style.module.scss';

const tKey = 'manage_v2.offer_with_bonus';

export const OfferWithBonus: React.FC = () => {
  const { offerWithBonusContent } = useManageContext();
  const category = offerWithBonusContent?.category!;
  const contextTKey = offerWithBonusContent?.tKey!;

  const { termsCheckbox } = useRemoteConfig();

  const isTermsAccepted = useSelector(selectIsTermsAccepted);
  const requiredTermsAccept = termsCheckbox.enabled;

  const { navigateNextScreen, navigatePrevSceen } = useManageNavigation();

  const { offer, productId, title, btnText, skipBtnText } = useOfferWithBonus();

  const {
    handlePurchasePlan,
    setVerifyUrl,
    setError,
    setSuccess,
    setIsDone,
    pending,
    isDone,
    verifyUrl,
    statusCode,
    error,
    success,
  } = useChangePlan(category, offer, productId);

  useEffect(() => {
    Analytics.trackEvent(category, EVENT_ACTION.OPEN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBackClick = () => {
    Analytics.trackEvent(category, EVENT_ACTION.CLOSE);
    navigatePrevSceen();
  };

  const handleSkipClick = () => {
    Analytics.trackEvent(category, EVENT_ACTION.SKIP);
    navigateNextScreen();
  }

  const handlePurchaseClick = () => {
    if (requiredTermsAccept) {
      if (isTermsAccepted) {
        handlePurchasePlan();
      }
    } else {
      handlePurchasePlan();
    }

  }

  return (
    <div className={classes.wrap}>
      {pending && <Preloader />}
      <Header
        title={<TM k={title} defaultKey={`${contextTKey}.title`} />}
        withBackButton
        withLight
        titleClassName={classes.title}
        onBackClick={handleBackClick}
      />
      {offerWithBonusContent?.renderContent(isDone)}

      <footer>
        <FixedButtonsInWrapper
          isFixedButton={false}
          isFixedLink={false}
          onButtonClick={handlePurchaseClick}
          onLinkClick={handleSkipClick}
          buttonTitle={tm(btnText, `${tKey}.button_text`)}
          linkTitle={tm(skipBtnText, `${tKey}.skip_btn`)}
        />
        {offer && <TermsBlock
          isShort
          activeProduct={offer}
        />}
      </footer>
      <OperationModals
        category={category}
        verifyUrl={verifyUrl}
        statusCode={statusCode}
        error={error}
        success={success}
        tKey={contextTKey}
        setVerifyUrl={setVerifyUrl}
        setError={setError}
        setSuccess={setSuccess}
        setIsDone={setIsDone}
      />
    </div>
  );
};
