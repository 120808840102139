import React, { useEffect } from 'react';

import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';
import FixedButtonsInWrapper from '@web-solutions/manage-subscription/src/manage/components/fixed-buttons-in-wrapper';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { t, T } from '../../../../localization';

import { goAppLink, useSliceDispatch } from '../../../slice';

import { useManageNavigation } from '../../../hooks/use-manage-navigate';
import { useManageContext } from '../../../hooks/use-manage-context';
import { Header } from '../../../components/header';
import { ErrorModal } from '../../../components/error-modal';

import { useUnsubscribe } from '../../../hooks/use-unsubscribe';

import { useGetIsScrolled } from './hooks'

import classes from './style.module.scss';

const tKey = 'manage_v2.benefits';
const category = 'manage_benefits';

export const ManageV2BenefitsPage: React.FC = () => {
  const dispatch = useSliceDispatch();
  const { mngSubBenefitsPage } = useRemoteConfig();
  const { fixedLink, fixedButton } = mngSubBenefitsPage;
  const { navigatePrevSceen } = useManageNavigation();
  const { benefitsContent } = useManageContext();
  const { unsubscribe, setErrorMessage, errorMessage } = useUnsubscribe(category);

  const isScrolled = useGetIsScrolled();

  useEffect(() => {
    if (!mngSubBenefitsPage.enabled) {
      unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    Analytics.trackEvent(category, EVENT_ACTION.OPEN);
  }, []);

  const handleBackClick = () => {
    Analytics.trackEvent(category, EVENT_ACTION.BACK);
    navigatePrevSceen();
  }

  const handleKeepClick = () => {
    Analytics.trackEvent(category, 'return_to_app');
    dispatch(goAppLink());
  };

  const handleContinueClick = () => {
    console.log(123)
    Analytics.trackEvent(category, 'continue');
    unsubscribe();
  };

  const handleErrorCloseClick = () => {
    Analytics.trackEvent(`${category}_error_modal`, EVENT_ACTION.CLOSE);
    setErrorMessage('');
  }

  const handleRetryClick = () => {
    Analytics.trackEvent(`${category}_terminate_retry`, EVENT_ACTION.CLICK);
    setErrorMessage('')
    unsubscribe();
  }

  return (
    <div className={classes.wrap}>
      <Header
        titleClassName={classes.title}
        subtitleClassName={classes.subtitle}
        title={t(`${tKey}.title`)}
        subtitle={t(`${tKey}.subtitle`)}
        onBackClick={handleBackClick}
        withBackButton
        withLight
      />

      {benefitsContent}

      <footer>
        <p className={classes.bottom_text}>
          <T k={`${tKey}.bottom_text`} />
        </p>
        <FixedButtonsInWrapper
          isFixedLink={fixedLink}
          isFixedButton={fixedButton}
          buttonTitle={t(`${tKey}.return_to_app`)}
          linkTitle={t(`${tKey}.continue`)}
          swapButtons
          onButtonClick={handleKeepClick}
          onLinkClick={handleContinueClick}
        />
      </footer>
      <div className={classNames(classes.scroll_indicator, { [classes.hidden]: isScrolled })}>
        {t(`${tKey}.scroll`)}
      </div>
      <ErrorModal
        tKey={`${tKey}.error_modal`}
        isOpen={!!errorMessage}
        message={errorMessage}
        onButtonClick={handleRetryClick}
        onCloseClick={handleErrorCloseClick}
      />
    </div>
  );
};
