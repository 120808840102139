import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { Period } from '@web-solutions/react-billing/constants';

import { remoteConfigSelector } from '@web-solutions/core/store/remote-config/selectors';

import { COUNTRY_VAT } from '@web-solutions/core/payment/constants';

import { ProductDetails } from '@web-solutions/core/store/billing/selectors';

//@ts-ignore
import { SUPPORT_EMAIL } from '@web-solutions/core/constants/general';

import { getActualPrices } from '@web-solutions/core/payment/base/product-info/components/simple-view/utilts';

import { Terms } from '@web-solutions/core/payment/components/payment-description/components/terms';
import { ContactUs } from '@web-solutions/core/payment/components/payment-description/components/contact-us';

import { T } from '../../../../localization';

import classes from './style.module.scss';

const subjectText = 'Manage payment description';

interface Props {
  activeProduct: ProductDetails;
  nextChargeDate: string,
  currentPlanPeriod: Period,
  className?: string,
  isMergedView?: boolean,
}

const tKey = 'manage.payment_description';
const useOriginalFields = true; // юзать поля не конвертируемые в разные валюты

const PaymentDescription: React.FC<Props> = ({ activeProduct, nextChargeDate, currentPlanPeriod, className, isMergedView }) => {
  const countryCode = useSelector((state: any) => state?.billing?.countryCode)

  const { policyLinks, configVAT, totalPriceType } = useSelector(remoteConfigSelector);

  const { price, descPrice } = getActualPrices({ activeProduct, totalPriceType, useOriginalFields })

  const displayTaxes = configVAT.display && !!COUNTRY_VAT[countryCode];

  const priceString = displayTaxes ? descPrice : price;

  const mainContent =
    <>
      <T
        k={`${tKey}.text`}
        tOptions={{
          plan: t(`core.plans.items_titles.${currentPlanPeriod}`),
          nextChargeDate,
          priceText: priceString,
          period: t(`core.payment_popup.price_texts.${activeProduct.period}`),
        }}
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        components={{ mblink: <a href={policyLinks.moneyBack} /> }}
      />
      <span> </span>
      <ContactUs
        text={<T k={`${tKey}.contact_us`} />}
        email={SUPPORT_EMAIL ?? ''}
        subjectText={subjectText}
      />
    </>

  const content =
    <>
      {isMergedView ? mainContent : <div className={classes.main_part}>{mainContent}</div>}
      <footer>
        <Terms text={<T k={`${tKey}.link`} />} hrefTerms={policyLinks.billingTerms} />
      </footer>
    </>

  return isMergedView ? content : <div className={classNames(classes.root, className)}>{content}</div>;
};

export default PaymentDescription;
