import { BaseSyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Period } from '@web-solutions/react-billing/constants';

import { PolicyText } from '@web-solutions/core/summary/plans/policy-text';

import PaymentDescription from '@web-solutions/core/payment/components/payment-description';

import PaymentDescriptionManage from '@web-solutions/manage-subscription/src/manage/components/plans/payment-description';

import { ProductDetails, selectIsTermsAccepted } from '@web-solutions/core/store/billing/selectors';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { setIsTermsAccepted } from 'core/store/billing/actions';


import classes from './style.module.scss';

interface Props {
  isManage?: boolean;
  activeProduct: ProductDetails;
  nextChargeDate?: string;
  currentPlanPeriod?: Period,
  isShort?: boolean;
  showPaymentDescription?: boolean;
  policyClassName?: string;
  descriptionClassName?: string;
}

export const TermsBlock: React.FC<Props> = ({
  isManage,
  activeProduct,
  nextChargeDate,
  currentPlanPeriod,
  isShort,
  policyClassName,
  descriptionClassName,
  showPaymentDescription = true,
}) => {
  const dispatch = useDispatch();
  const { termsCheckbox, showTermsAtPaymentModal } = useRemoteConfig();
  const enabledCheckbox = termsCheckbox.enabled;

  const status = useSelector(selectIsTermsAccepted);

  const handleCheckbBoxClick = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    const newStatus = status === null ? !termsCheckbox.active : !status;
    Analytics.trackEvent('terms_checkbox', EVENT_ACTION.CLICK, { status: newStatus });
    dispatch(setIsTermsAccepted(newStatus));
  };

  const renderPaymentDescription = (isMergedView = false) => {
    const commonProps = { activeProduct, isMergedView };
    return isManage
      ? (
        <PaymentDescriptionManage
          {...commonProps}
          nextChargeDate={nextChargeDate!}
          currentPlanPeriod={currentPlanPeriod!}
        />
      )
      : (
        <PaymentDescription {...commonProps} isShort={isShort} />
      );
  };

  return (
    enabledCheckbox ?
      <div className={classes.terms_block}
        onClick={handleCheckbBoxClick}
      >
        <PolicyText
          className={classNames(classes.policy, { [classes.error]: status === false })}
          status={status}
        >
          {!showTermsAtPaymentModal && renderPaymentDescription(true)}
        </PolicyText>
      </div >
      :
      <>
        <PolicyText className={policyClassName} />
        {showPaymentDescription && !showTermsAtPaymentModal && (
          <div className={descriptionClassName}>{renderPaymentDescription()}</div>
        )}
      </>
  )
}