import { useResolvedPath, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import { PaymentSystem } from '@web-solutions/react-billing';

import { selectPaymentSystem, selectHasBeenOnEmailCancelPage, selectProduct, selectHasBeenOnReviewStep } from '../../slice';


import { REASONS_IDS_V2 } from '../../constants/general';

import { ROUTES_V2 } from '../../constants/routes';
import { RemoteConfig } from '../../constants/remote-config';
import { useManageContext } from '../../hooks/use-manage-context';

const shortVariant = [
  ROUTES_V2.MAIN,
  ROUTES_V2.DONT_MISS,
  ROUTES_V2.REVIEW,
  ROUTES_V2.REASON,
]

const fastCancelVariant = [
  ROUTES_V2.MAIN,
  ROUTES_V2.REASON,
]

const defaultStart = [
  ROUTES_V2.MAIN,
  ROUTES_V2.DONT_MISS,
  ROUTES_V2.PAUSE,
  ROUTES_V2.REVIEW,
  ROUTES_V2.REASON,
]

const defaultEnd = [
  ROUTES_V2.BENEFITS,
  ROUTES_V2.CANCEL_SUCCESS
]

const fastCancelEnd = [
  ROUTES_V2.CANCEL_SUCCESS
]

const variant_1 = [
  ROUTES_V2.OFFER_FREE_PERIOD,
]

const variant_2 = [
  ROUTES_V2.OFFER_WITH_BONUS
];

const variant_3 = [
  ROUTES_V2.OFFER_CHEAP_PRODUCT,
]

const variant_4 = [
  ROUTES_V2.PLANS,
]

const ROUTE_VARIANTS = {
  [REASONS_IDS_V2.NO_TIME]: variant_1,
  [REASONS_IDS_V2.PROBLEMS]: variant_1,
  [REASONS_IDS_V2.DONT_LIKE]: variant_1,
  [REASONS_IDS_V2.TRIAL]: variant_1,
  [REASONS_IDS_V2.ADVISORS]: variant_2,
  [REASONS_IDS_V2.REPORT]: variant_3,
  [REASONS_IDS_V2.PRICE]: variant_3,
  [REASONS_IDS_V2.OTHER]: variant_3,
  [REASONS_IDS_V2.CANT_SHARE]: variant_3,
  [REASONS_IDS_V2.LONGER_PLAN]: variant_4,
  [REASONS_IDS_V2.DONT_USE]: [],
}

export const useNavigateManageV2 = () => {
  const {
    mngSubOfferFreePeriodPage,
    mngSubOfferCheapProductPage,
    mngSubReasonPage,
    mngDontMissPage,
    isShortManageVariant,
    manageWithReviewStep,
    mngSubPausePage,
    mngOfferWithBonusPage,
  } = useRemoteConfig() as RemoteConfig;
  const paymentSystem = useSelector(selectPaymentSystem);
  const currentProduct = useSelector(selectProduct);
  const isHasBeenOnEmailCancelPage = useSelector(selectHasBeenOnEmailCancelPage);
  const isHasBeenOnReviewPage = useSelector(selectHasBeenOnReviewStep);
  const { dontMissConfig, fastCancelMode, pauseSubscriptionConfig = { enabled: false } } = useManageContext();
  const navigate = useNavigate();
  const match = useResolvedPath("");

  const getEnd = () => {
    if (fastCancelMode) {
      return fastCancelEnd
    }

    return defaultEnd
  }

  const getActualVariant = () => {
    const params = queryString.parse(window.location.search);
    const reason = params.reason as unknown as string;
    const routes = ROUTE_VARIANTS[reason] || [];
    const end = getEnd();

    if (isShortManageVariant) {
      return [...shortVariant, ...routes, ...end];
    }

    if (fastCancelMode) {
      return [...fastCancelVariant, ...end];
    }

    return [...defaultStart, ...routes, ...end];
  }

  const getNextPage = (location: string) => {
    if (isHasBeenOnEmailCancelPage && location !== ROUTES_V2.BENEFITS) {
      return ROUTES_V2.BENEFITS
    } else {
      const routes = getActualVariant();
      const nextPageIndex = routes.findIndex((route) => route === location) + 1;
      return routes[nextPageIndex]
    }
  }

  const getPrevPage = (location: string) => {
    const routes = getActualVariant();
    const prevPageIndex = routes.findIndex((route) => route === location) - 1;
    return routes[prevPageIndex]
  }

  const isPageAvailable = (page: string) => {
    switch (page) {
      case ROUTES_V2.OFFER_FREE_PERIOD:
        return mngSubOfferFreePeriodPage.enabled && !!pauseSubscriptionConfig?.enabled;
      case ROUTES_V2.OFFER_CHEAP_PRODUCT:
        const cheapProduct = mngSubOfferCheapProductPage[currentProduct?.currency!] ?? mngSubOfferCheapProductPage.USD
        return mngSubOfferCheapProductPage.enabled && cheapProduct.productId !== currentProduct?.id && paymentSystem === PaymentSystem.SOLIDGATE;
      case ROUTES_V2.REVIEW:
        return manageWithReviewStep.enabled && !isHasBeenOnReviewPage;
      case ROUTES_V2.REASON:
        return mngSubReasonPage.enabled;
      case ROUTES_V2.DONT_MISS:
        return mngDontMissPage.enabled && !!dontMissConfig?.enabled
      case ROUTES_V2.PAUSE:
        return !!mngSubPausePage?.enabled && !!pauseSubscriptionConfig?.enabled;
      case ROUTES_V2.OFFER_WITH_BONUS: // today it is UnlimAdvisorPage
        const offerWithBonus = mngOfferWithBonusPage[currentProduct?.currency!] ?? mngOfferWithBonusPage.USD;
        return offerWithBonus?.productId !== currentProduct?.id && paymentSystem === PaymentSystem.SOLIDGATE;
      default:
        return true
    }
  };

  const navigateScreen = (mode: 'prev' | 'next') => (isReplace?: boolean) => {
    let currentPage = match.pathname;
    let isPresent = false;
    let nextPage = '';

    while (!isPresent) {
      nextPage = mode === 'prev' ? getPrevPage(currentPage) : getNextPage(currentPage)
      isPresent = isPageAvailable(nextPage);
      if (!isPresent) {
        currentPage = nextPage
      }
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    const params = queryString.parse(window.location.search);
    const search = queryString.stringify(params);

    navigate(
      {
        pathname: nextPage,
        search: search,
      },
      {
        state: {
          inFlow: true,
        },
        replace: isReplace
      }
    );
  }

  return { navigateNextScreen: navigateScreen('next'), navigatePrevSceen: navigateScreen('prev'), isPageAvailable }
}