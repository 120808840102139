import { useState } from 'react';
import { toast } from 'react-toastify';

import { t } from '@web-solutions/module-localization';

import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { useSliceDispatch, cancelSubscription } from '../slice';

import { requestCancel } from '../../terminate/slice';

import { useManageNavigation } from './use-manage-navigate';

const tKey = "manage.terminate_subscription"


export const useUnsubscribe = (category: string) => {
  const dispatch = useSliceDispatch();
  const { navigateNextScreen } = useManageNavigation();
  const [errorMessage, setErrorMessage] = useState('');

  const { mngSubEmailCancelPage, mngFreeGiftCancelPage } = useRemoteConfig();

  const requestCancelSub = () => {
    dispatch(requestCancel({ trigger: 'manage', isEnableLang: mngSubEmailCancelPage?.isEnableLang }))
      .unwrap()
      .then(() => {
        navigateNextScreen(true);
      })
      .catch((error: any) => {
        console.warn(error);
        const message = error?.data?.message ? error.data.message : t(`${tKey}.error_msg`);
        Analytics.trackEvent(category, 'error', { message: message });
        toast(message);
      });
  }

  const cancelSub = () => {
    dispatch(cancelSubscription({ withFreeGift: mngFreeGiftCancelPage?.enabled }))
      .unwrap()
      .then(() => {
        Analytics.trackEvent(category, EVENT_ACTION.SUCCESS);
        navigateNextScreen(true);
      }, err => {
        Analytics.trackEvent(category, EVENT_ACTION.ERROR, { message: err.message });
        setErrorMessage(err.message);
      });
  }

  const unsubscribe = () => {
    if (mngSubEmailCancelPage.enabled) {
      requestCancelSub();
    } else {
      cancelSub();
    }
  }

  return ({
    unsubscribe,
    cancelSub,
    setErrorMessage,
    errorMessage
  })
}