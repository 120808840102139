export const ROUTES = {
  MAIN: '/manage',
  REASON: '/manage/reason',
  PLANS: '/manage/plans',
  BENEFITS: '/manage/benefits',
  PROGRESS: '/manage/progress',
  OFFER_FREE_PERIOD: '/manage/offer_free_period',
  CANCEL_SUCCESS: '/manage/cancel_success',
  EMAIL_CANCEL: '/manage/email_cancel',
  FAREWELL_OFFER: '/manage/farewell_offer',
  OFFER_CHEAP_PRODUCT: '/manage/product_cheap',
  SINGLE_OFFER_CHEAP_PRODUCT: '/manage/product_cheap_single',
  TERMINATE_SUBSCRIPTION: '/manage/terminate_subscription',
  FREE_GIFT_CANCEL: '/manage/free_gift_cancel',
  SURVEY: '/manage/survey',
  REVIEW: '/manage/review',
  DONT_MISS: '/manage/dont_miss',
};

export const ROUTES_V2 = {
  MAIN: '/manage',
  REASON: '/manage/reason',
  PLANS: '/manage/plans',
  BENEFITS: '/manage/benefits',
  CANCEL_SUCCESS: '/manage/cancel_success',
  OFFER_FREE_PERIOD: '/manage/offer_free_period',
  EMAIL_CANCEL: '/manage/email_cancel',
  OFFER_CHEAP_PRODUCT: '/manage/product_cheap',
  SINGLE_OFFER_CHEAP_PRODUCT: '/manage/product_cheap_single',
  REVIEW: '/manage/review',
  DONT_MISS: '/manage/dont_miss',
  PAUSE: '/manage/pause',
  OFFER_WITH_BONUS: '/manage/offer_with_bonus',
};

