import { type FC } from 'react'

import { t, TM } from '@web-solutions/manage-subscription/src/localization';

import type { RemoteConfig } from '@web-solutions/core/constants/remote-config';

import { Button, Text } from '@web-solutions/core/ui-elements';

import { FixedButtonsInWrapper } from '@web-solutions/manage-subscription/src/manage/components/fixed-buttons-in-wrapper';

import { baseTKey } from '../../constants';

import type { FormattedPeriod } from '../../types';

import classes from './style.module.scss'

const tKey = `${baseTKey}.action_btns`;

interface ActionButtonsProps {
  mode: Required<NonNullable<RemoteConfig['mngSubPausePage']>>['mode'],
  activePausePeriod: FormattedPeriod,
  isNavigatedFromMain: boolean
  onContinueClick: () => void,
  onPauseSubClick: (period: FormattedPeriod) => () => void,
  onBackClick: () => void
}

export const ActionButtons: FC<ActionButtonsProps> = ({ mode, activePausePeriod, isNavigatedFromMain, onContinueClick, onPauseSubClick, onBackClick }) => {
  const isWillBePaused = activePausePeriod.value !== 0;

  return (
    <footer className={classes.footerWrapper}>
      {mode === 'with_features' ?
        (
          <>
            <Text className={classes.description}>
              <TM k={`${baseTKey}.content.${mode}.pause_plan_for`} tOptions={{ period: t(activePausePeriod.titleKey, { count: activePausePeriod.amount }) }} />
            </Text>
            <FixedButtonsInWrapper
              buttonTitle={t(`${tKey}.pause`)}
              linkTitle={t(`${tKey}.continue`)}
              isFixedLink={false}
              isFixedButton={false}
              onButtonClick={onPauseSubClick(activePausePeriod)}
              onLinkClick={onContinueClick}
            />
          </>
        ) :
        (
          <Button
            titleClassName={classes.button_title}
            title={t(`${tKey}.${isWillBePaused ? 'pause' : isNavigatedFromMain ? 'back' : 'continue'}`)}
            onClick={isWillBePaused ? onPauseSubClick(activePausePeriod) : isNavigatedFromMain ? onBackClick : onContinueClick}
          />
        )}
    </footer>
  )
}
