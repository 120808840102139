import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useResolvedPath } from 'react-router-dom'

import { Preloader, Text } from '@web-solutions/core/ui-elements';

import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { useHeight } from '@web-solutions/core/hooks/use-height';

import { useRemoteConfig } from '../../../../../core/hooks/use-remote-config';

import { Header } from '../../components/header';

import { goAppLink, useSliceDispatch, selectProduct } from '../../slice';

import { t, TM } from '../../../localization';

import { useOffer } from '../../hooks/use-offer';
import { useManageContext } from '../../hooks/use-manage-context';
import { useManageNavigation } from '../../hooks/use-manage-navigate';
import { ROUTES } from '../../constants/routes';

import { OperationModals } from '../../components/operation-modals';
import FixedButtonsInWrapper from '../../components/fixed-buttons-in-wrapper';

import { useChangePlan } from '../../hooks/use-change-plan';

import classes from './style.module.scss';

const tKey = 'manage.offer_cheap_product';

const category = "manage_offer_cheap_product"

export const OfferCheapProductPage: React.FC = () => {
  const { pathname } = useResolvedPath('');
  const { mngSubOfferCheapProductPage, manageVersion } = useRemoteConfig();
  const currentProduct = useSelector(selectProduct);
  const product = mngSubOfferCheapProductPage[currentProduct?.currency!] ?? mngSubOfferCheapProductPage.USD
  const price = product?.price
  const productId = product?.productId
  const { backEnabled, subtitle, type } = mngSubOfferCheapProductPage;
  const { navigateNextScreen, navigatePrevSceen } = useManageNavigation();
  const { offerCheapProductContent } = useManageContext();
  const dispatch = useSliceDispatch();
  const { period, offer } = useOffer(productId);

  const containerHeight = useHeight();

  const {
    handlePurchasePlan,
    setVerifyUrl,
    setError,
    setSuccess,
    pending,
    verifyUrl,
    statusCode,
    error,
    success,
  } = useChangePlan(category, offer, productId);

  const isSingleOffer = pathname === ROUTES.SINGLE_OFFER_CHEAP_PRODUCT

  const isTopImageType = type === 'top_image';

  useEffect(() => {
    Analytics.trackEvent(category, EVENT_ACTION.OPEN, { type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseClick = () => {
    Analytics.trackEvent(category, EVENT_ACTION.CLOSE);
    if (isSingleOffer) {
      dispatch(goAppLink());
    } else {
      navigatePrevSceen();
    }
  };

  const handleRejectClick = () => {
    Analytics.trackEvent(category, EVENT_ACTION.SKIP);
    navigateNextScreen();
  }

  const handlePurchaseClick = () => {
    handlePurchasePlan();
  }

  return (
    <div className={classnames(classes.wrap, { [classes.v2]: manageVersion === 'v2' })} style={{ minHeight: containerHeight }}>
      {pending && <Preloader />}
      <Header
        title={t(`${tKey}.title`)}
        subtitle={isTopImageType ? undefined : t(`${tKey}.text`)}
        content={isTopImageType ? undefined : offerCheapProductContent?.title}
        withBackButton={backEnabled}
        withLight
        onBackClick={handleCloseClick}
      />
      {offerCheapProductContent?.content}
      <div>
        {!isTopImageType && (
          <Text className={classes.subtitle}>
            <TM k={subtitle} defaultKey={`${tKey}.trial_desc`} tOptions={{ ...offer, period, trialDays: offer?.trialDays }} />
          </Text>
        )}
        {isTopImageType && (
          <Text className={classes.price_title}>{t(`${tKey}.price_title`)}</Text>
        )}
        <Text className={classnames(
          classes.price_text,
          classes.text,
          { [classes.alt_price_text]: isTopImageType }
        )}>
          <TM k={price} defaultKey={`${tKey}.price`} tOptions={{ ...offer, period, trialPrice: offer?.trialPrice }} />
        </Text>
        {isTopImageType && (
          <Text className={classes.description}>
            <TM k={subtitle} defaultKey={`${tKey}.description`} tOptions={{ ...offer, period, trialDays: offer?.trialDays }} />
          </Text>
        )}
      </div>
      <FixedButtonsInWrapper
        isFixedLink={false}
        isFixedButton={false}
        onButtonClick={handlePurchaseClick}
        onLinkClick={isSingleOffer ? handleCloseClick : handleRejectClick}
        buttonTitle={t(`${tKey}.button`)}
        linkTitle={t(`${tKey}.reject_button`)}
      />

      <OperationModals
        category={category}
        verifyUrl={verifyUrl}
        statusCode={statusCode}
        error={error}
        success={success}
        setVerifyUrl={setVerifyUrl}
        setError={setError}
        setSuccess={setSuccess}
        tKey={tKey}
      />
    </div>
  );
};
