import React, { useEffect } from 'react';

import Analytics from '@web-solutions/module-analytics';
import { Title, Button, Text, Modal } from '@web-solutions/core/ui-elements';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { t, T } from '../../../localization';

import { useManageContext } from '../../hooks/use-manage-context';

import { ReactComponent as GestureIcon } from './icons/gesture.svg';

import classes from './style.module.scss';

const tKey = 'manage.coming_soon_modal';

interface ComingSoonModalProps {
  isOpen: boolean,
  category: string,
  onClose: () => void,
}

export const ComingSoonModal: React.FC<ComingSoonModalProps> = ({
  isOpen,
  category,
  onClose,
}) => {
  const { comingSoonModalContent } = useManageContext();

  const handleClose = () => {
    Analytics.trackEvent(category, EVENT_ACTION.CLOSE);
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      Analytics.trackEvent(category, EVENT_ACTION.OPEN);
    }
  }, [category, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      className={classes.modal}
      overlayClassName={classes.overlay}
    >
      <div className={classes.content}>
        <div className={classes.close} onClick={handleClose}>
          <GestureIcon />
        </div>
        {comingSoonModalContent}
        <Title level="h2" className={classes.title}>
          <T k={`${tKey}.title`} />
        </Title>
        <Text className={classes.text}>{t(`${tKey}.content`)}</Text>
      </div>
      <Button
        onClick={handleClose}
        iconRight={false}
        className={classes.button}
        title={t(`${tKey}.button`)}
        titleClassName={classes.button__title}
      />
    </Modal>
  );
};
