import type { FC } from 'react'
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { t, T } from '@web-solutions/manage-subscription/src/localization';

import { TerminateTemplate } from '@web-solutions/manage-subscription/src/terminate/components/template'

import { useManageContext } from '../../../hooks/use-manage-context';

import { selectSubscription } from '../../../slice';

const APP_NAME = process.env.REACT_APP_TITLE;

const tKey = 'terminate.success';

export const Default: FC = () => {
  const { logo, defaultCancelSuccessContent } = useManageContext();
  const subscription = useSelector(selectSubscription);

  return (
    <TerminateTemplate
      title={t(`${tKey}.title`)}
      text={
        <T
          k={`${tKey}.description`}
          tOptions={{ expireDate: dayjs(subscription?.expire_at).format('D MMMM YYYY') }}
        />
      }
      logo={logo}
      subject={`[${APP_NAME}] Your subscription has been canceled (Manage)`}
    >
      {defaultCancelSuccessContent}
    </TerminateTemplate>
  )
};
