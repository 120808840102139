import React from 'react';

import { ModalDialog, Button, Input, Title } from '@web-solutions/core/ui-elements';
import { t } from '@web-solutions/module-localization';
import Analytics from '@web-solutions/module-analytics';

import { ReactComponent as Icon } from '../icons/icon-gray.svg';

import classes from './style.module.scss';

interface Props {
  isOpen: boolean,
  onSubmit: (data: { first_name: string, last_name: string, vpa: string, zip: string, phone: string }) => void,
  onCloseClick: () => void,
}

export const UPIForm: React.FC<Props> = ({
  isOpen,
  onSubmit,
  onCloseClick,
}) => {
  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
    e.preventDefault();

    const form = e.target as typeof e.target & {
      first_name: { value: string };
      last_name: { value: string };
      vpa: { value: string };
      zip: { value: string };
      phone: { value: string };
    };

    const formData = {
      first_name: form.first_name.value,
      last_name: form.last_name.value,
      vpa: form.vpa.value,
      zip: form.zip.value,
      phone: form.phone.value,
    };

    Analytics.trackEvent('upi', 'form_submit', formData);
    onSubmit(formData);
  }

  return (
    <ModalDialog
      isOpen={isOpen}
      className={classes.modal}
      onCloseClick={onCloseClick}
    >
      <Title
        level='h3'
        className={classes.title}
      >
        <Icon />
        UPI
      </Title>
      <form
        className={classes.form}
        onSubmit={handleSubmit}
      >
        <Input
          placeholder={t('core.first_name')}
          className={classes.input}
          name="first_name"
          required
          maxLength={60}
          pattern="^[a-zA-Z]+$"
        />
        <Input
          placeholder={t('core.last_name')}
          className={classes.input}
          name="last_name"
          required
          maxLength={60}
          pattern="^[a-zA-Z]+$"
        />
        <Input
          placeholder={t('core.vpa')}
          className={classes.input}
          name="vpa"
          required
          pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+$"
        />
        <Input
          placeholder={t('core.zip_code')}
          className={classes.input}
          name="zip"
          pattern="^[0-9]{5,6}(-[0-9]{4})?$"
          required
        />
        <Input
          placeholder={t('core.phone_number')}
          className={classes.input}
          name="phone"
          required
          pattern="^[+]?([0-9]\s?){10,15}$"
        />
        <Button
          type="submit"
          title={t('_continue')}
          className={classes.btn}
        />
      </form>
    </ModalDialog>
  );
};
