import { useState } from 'react';

import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { createOrderDescription } from '@web-solutions/core/utils/billing';

import { ProductDetails } from '@web-solutions/core/store/billing/selectors';

import { t } from '../../localization';

import { useSliceDispatch, changePlan } from '../slice';

export const useChangePlan = (category: string, offer?: ProductDetails, productId?: string | number) => {
  const dispatch = useSliceDispatch();

  const [pending, setPending] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [statusCode, setStatusCode] = useState('');
  const [verifyUrl, setVerifyUrl] = useState('');

  const handlePurchasePlan = () => {
    Analytics.trackEvent(category, 'change_plan');
    setPending(true);

    const orderDescription = createOrderDescription(offer!);
    dispatch(changePlan({ productId: productId!, isNewSubscription: true, orderDescription }))
      .unwrap()
      .then((r: any) => {
        const url = r?.verify_url
        if (url) {
          setVerifyUrl(url);
        } else {
          handleSuccess();
        }
      })
      .catch((e: any) => {
        Analytics.trackEvent(category, EVENT_ACTION.ERROR, { message: e?.message, code: e?.code, });
        setError(e?.message || t('manage.error.text'));
        setStatusCode(e?.code);
      })
      .finally(() => {
        setPending(false);
      })
  }


  const handleSuccess = () => {
    Analytics.trackEvent(category, EVENT_ACTION.SUCCESS);
    setVerifyUrl('');
    setSuccess(true);
  }

  return {
    handlePurchasePlan,
    handleSuccess,
    setVerifyUrl,
    setError,
    setStatusCode,
    setPending,
    setIsDone,
    setSuccess,
    pending,
    verifyUrl,
    statusCode,
    error,
    success,
    isDone,
  }
}