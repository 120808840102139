import React from 'react';

import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Verify3dsModal } from '@web-solutions/core/containers/verify-3ds';
import ErrorPopup from '@web-solutions/core/payment/components/error-popup';

import { useNavigateManage } from '../../hooks/use-navigate-next-screen';


import { SuccessModal } from '../success-modal';

interface Props {
  setVerifyUrl: (url: string) => void;
  setError: (error: string) => void;
  setSuccess: (success: boolean) => void;
  setIsDone?: (isDone: boolean) => void;
  category: string;
  verifyUrl: string;
  statusCode: string;
  error: any;
  success: boolean;
  tKey: string,
}

export const OperationModals: React.FC<Props> = ({
  setVerifyUrl,
  setError,
  setSuccess,
  setIsDone,
  category,
  verifyUrl,
  statusCode,
  error,
  success,
  tKey,
}) => {
  const { navigateNextScreen } = useNavigateManage();

  const handleSuccess = () => {
    Analytics.trackEvent(category, EVENT_ACTION.SUCCESS);
    setVerifyUrl('');
    setSuccess(true)
  }

  const handleCloseSuccessModal = () => {
    setSuccess(false);
    setIsDone?.(true);
  }

  const handleFail = () => {
    Analytics.trackEvent(category, EVENT_ACTION.ERROR, { message: '3DS error' });
    setVerifyUrl('');
    setError('3DS error');
  }

  const handleCloseErrorClick = () => {
    Analytics.trackEvent(`${category}_error_modal`, EVENT_ACTION.CLOSE);
    setError('');
    navigateNextScreen();
  }

  return (
    <>
      <SuccessModal
        category={`${category}_success_modal`}
        isOpen={success}
        tKey={`${tKey}.success_modal`}
        onClose={setIsDone ? handleCloseSuccessModal : undefined}
      />

      <ErrorPopup
        visible={!!error}
        onClose={handleCloseErrorClick}
        onSubmit={handleCloseErrorClick}
        buttonTitle={`OK`}
        statusCode={statusCode}
      />

      <Verify3dsModal
        verifyUrl={verifyUrl}
        onSuccess={handleSuccess}
        onFail={handleFail}
      />
    </>
  );
};